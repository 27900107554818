import React, { useState, useRef, useEffect } from "react";
import Axios from "axios";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Switch from "react-input-switch";

import plus from "../../imgs/plus.png";
import plusW from "../../imgs/plusW.png";
import checkImgW from "../../imgs/checkW.png";
import checkImg from "../../imgs/check.png";
import closeX from "../../imgs/closeX.png";
import closeXDark from "../../imgs/closeXDark.png";

//make finance atleast 1

let Store = ({
  uid,
  darkmode,
  preferences,
  togglePrefSettings,
  isAdmin,
  dir,
  feeds,
  sku,
  has1p,
  loaded,
}) => {
  const [url, setUrl] = useState(window.location.href);
  const [menuTotal, setMenuTotal] = useState({
    finance: [
      {
        name: "stocks",
        on: true,
        preBought: preStocks,
        bought: preferences.shop?.stocks,
      },
      {
        name: "crypto",
        on: true,
        preBought: preCrypto,
        bought: preferences.shop?.crypto,
      },
      {
        name: "markets",
        on: true,
        preBought: preMarkets,
        bought: preferences.shop?.markets,
      },
      {
        name: "forex",
        on: true,
        preBought: preForex,
        bought: preferences.shop?.forex,
      },
      {
        name: "comms",
        on: true,
        preBought: preComms,
        bought: preferences.shop?.comms,
      },
    ],
    sports: [
      {
        name: "mlb",
        on: true,
        preBought: preMlb,
        bought: preferences.shop?.mlb,
      },
      {
        name: "nfl",
        on: true,
        preBought: preNfl,
        bought: preferences.shop?.nfl,
      },
      {
        name: "ncaa",
        on: true,
        preBought: preNcaa,
        bought: preferences.shop?.ncaa,
      },
      {
        name: "nba",
        on: true,
        preBought: preNba,
        bought: preferences.shop?.nba,
      },
      {
        name: "nhl",
        on: true,
        preBought: preNhl,
        bought: preferences.shop?.nhl,
      },
      {
        name: "nippon",
        on: true,
        preBought: preNippon,
        isAdmin: true,
        cost: 2.5,
        bought: preferences.shop?.nippon,
      },
      {
        name: "pga",
        on: true,
        preBought: prePga,
        isAdmin: true,
        cost: 5,
        bought: preferences.shop?.pga,
      },
    ],
    weather: [
      {
        name: "all",
        on: true,
      },
    ],
  });

  if (uid == "8Bp9Ph0ILocLxr3eIGX7B2Uv1wA2") {
    isAdmin = true;
  }

  useEffect(() => {
    if (url.includes("cancel")) {
      console.log("Payment was cancelled");
    }
  }, [url]);

  useEffect(() => {
    if (dir == "cancel") {
      console.log("cancel, load prior checkout - localstorage");
      let newCheckout = [...checkout];
      newCheckout.push({ type: "sports", name: "nippon" });
      setCheckout(newCheckout);
      setShowCheckout(true);
      //this.setState({ activePrefUpper: "store" });
    }

    if (dir == "success") {
      console.log("success, load prior checkout", dir);
      console.log("success- ", preferences);
      console.log("clearn local storage cart");
      // Extract session ID from URL
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");
      console.log("Stripe session ID:", sessionId);

      subSuccess(sessionId);
      //this.setState({ activePrefUpper: "store" });

      setShowSuccess("loading");
    }
  }, [dir]);

  useEffect(() => {
    console.log("preferences", preferences);
    if (preferences?.menu) {
      console.log("store- preferences.menu", preferences.menu, loaded);
      setMenu(preferences.menu);
    }
  }, [preferences]);

  let [menu, setMenu] = useState(preferences.menu);
  let [currentMenu, setCurrentMenu] = useState(preferences.currentMenu);
  let [display, setDisplay] = useState(null);
  let [checkout, setCheckout] = useState([]);
  let [hasOnceAdded, setHasOnceAdded] = useState(false);
  let [showCheckout, setShowCheckout] = useState(false);
  let [showSuccess, setShowSuccess] = useState(false);
  let [billingInterval, setBillingInterval] = useState("month");

  //on success purchase, update menu and menuTotal

  useEffect(() => {
    console.log("currentMenu- useEffect", currentMenu);
    console.log("menu- useEffect", menu);
    console.log("menuTotal- useEffect", menuTotal);

    //pga
    if (
      currentMenu?.sports?.find(
        (sport) => sport.name === "pga" && sport.on === true
      )
    ) {
      let newMenu = { ...menu };
      if (!newMenu.sports) newMenu.sports = []; // Initialize if undefined

      let pgaIndex = newMenu.sports?.findIndex(
        (sport) => sport.name === "pga" && sport.on === true
      );
      if (pgaIndex >= 0) {
        newMenu.sports[pgaIndex].on = true;
      } else {
        newMenu.sports.push({ name: "pga", on: true });
      }
      console.log("menu- after pga", menuTotal);
      setMenu(newMenu);

      let newMenuTotal = { ...menuTotal };
      if (!newMenuTotal.sports) newMenuTotal.sports = []; // Initialize if undefined

      let pgaIndex2 = newMenuTotal.sports.findIndex(
        (sport) => sport.name === "pga"
      );
      if (pgaIndex2 >= 0) {
        newMenuTotal.sports[pgaIndex2].on = true;
        newMenuTotal.sports[pgaIndex2].bought = true;
      }
      console.log("menuTotal- after pga", newMenuTotal);
      setMenuTotal(newMenuTotal);
    }

    //nippon
    if (
      currentMenu?.sports?.find(
        (sport) => sport.name === "nippon" && sport.on === true
      )
    ) {
      let newMenu = { ...menu };
      if (!newMenu.sports) newMenu.sports = []; // Initialize if undefined

      let nipponIndex = newMenu.sports?.findIndex(
        (sport) => sport.name === "nippon" && sport.on === true
      );
      if (nipponIndex >= 0) {
        newMenu.sports[nipponIndex].on = true;
      } else {
        newMenu.sports.push({ name: "nippon", on: true });
      }
      console.log("menu- after nippon", menuTotal);
      setMenu(newMenu);

      let newMenuTotal = { ...menuTotal };
      if (!newMenuTotal.sports) newMenuTotal.sports = []; // Initialize if undefined

      let nipponIndex2 = newMenuTotal.sports.findIndex(
        (sport) => sport.name === "nippon"
      );
      if (nipponIndex2 >= 0) {
        newMenuTotal.sports[nipponIndex2].on = true;
        newMenuTotal.sports[nipponIndex2].bought = true;
      }
      console.log("menuTotal- after nippon", newMenuTotal);
      setMenuTotal(newMenuTotal);
    }
  }, [currentMenu]);

  useEffect(() => {
    //probably only if canceled
    //maybe do session id, and if still same local session, then load /and or cancel
    if (url.includes("cancel")) {
      const savedCart = localStorage.getItem("qCart");
      if (savedCart) {
        try {
          const parsedCart = JSON.parse(savedCart);
          setCheckout(parsedCart);
        } catch (err) {
          console.error("Error parsing saved cart:", err);
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log("checkout - save , qCart", checkout);
    localStorage.setItem("qCart", JSON.stringify(checkout));
  }, [checkout]);

  useEffect(() => {
    getUser();
  }, [uid]);

  let getUser = async () => {
    let res = await Axios.get(`https://quotron.co/user/${uid}`);
    if (res.data?.length > 0) {
      let user = res.data[0];
      console.log("display in", user.display || null);
      setDisplay(user.display || null);
    }
  };

  console.log("loaded-", loaded);

  let subSuccess = async (sessionId) => {
    try {
      let res = await Axios.get(
        `https://quotron.co/subSuccess?session_id=${sessionId}`
      );
      console.log("subSuccess loaded-", loaded, " res,", res.data);

      let shopBought = res.data?.shopBought || {};
      let currentMenu = res.data?.currentMenu || {};
      //  setShopBought(shopBought);
      setCurrentMenu(currentMenu);
      setShowSuccess("completed");
      window.history.replaceState({}, "", "/storeSuccess");
    } catch (err) {
      console.error("subSuccess:", err);
      setShowSuccess("error");
    }
  };

  if (sku?.type == "stocks") {
  }

  let preStocks = sku?.type == "stocks" || !sku ? true : false;
  let preCrypto = sku?.type == "crypto" || !sku ? true : false;
  let preMarkets = !sku ? true : false;
  let preForex = !sku ? true : false;
  let preComms = !sku ? true : false;

  let preMlb = sku?.type == "sports" || !sku ? true : false;
  let preNfl = sku?.type == "sports" || !sku ? true : false;
  let preNcaa = sku?.type == "sports" || !sku ? true : false;
  let preNba = sku?.type == "sports" || !sku ? true : false;
  let preNhl = sku?.type == "sports" || !sku ? true : false;
  let preNippon = false;
  let prePga = false;

  //payment idea
  /*
      <div
        style={{
          opacity: 0.9,
          fontSize: 15,
          marginRight: 8,
        }}
        className={"profileTextSub"}
      >{`$5/ mo`}</div>
  */

  let addToCheckout = async (type, name, cost) => {
    console.log("addToCheckout", type, name, cost);
    let newCheckout = [...checkout];
    newCheckout.push({ type, name, cost });

    await new Promise((resolve) => {
      setCheckout(newCheckout);

      //can i check how close the page is to the bottom?
      let currentScroll = window.scrollY;
      let windowHeight = window.innerHeight;
      let documentHeight = document.documentElement.scrollHeight;
      let distanceToBottom = documentHeight - currentScroll - windowHeight;

      if (distanceToBottom < 100) {
        setHasOnceAdded(true);
      }

      resolve();
    });
  };

  let removeFromCheckout = async (type, name) => {
    let newCheckout = checkout.filter((a) => a.type != type || a.name != name);
    setCheckout(newCheckout);
  };

  let makeActive = async (type, name) => {
    let doCheckout = false;

    //check bought true pga after sever.js update
    let thisItem = menuTotal[type].find((a) => a.name == name);
    console.log("thisItem", thisItem);
    if (!thisItem?.bought && !thisItem?.preBought) {
      doCheckout = true;
    }

    //if already in checkout
    let isInCheckout = checkout.find((a) => a.type == type && a.name == name);
    if (isInCheckout) {
      removeFromCheckout(type, name);
      return;
    }

    //if unbought, add to checkout

    //check bought true pga after sever.js update
    console.log("doCheckout", doCheckout);
    if (doCheckout) {
      let cost = menuTotal[type].find((a) => a.name == name)?.cost;
      if (name == "nippon") {
        await addToCheckout(type, name, cost);
        //refreshPaypal();

        let currentScroll = window.scrollY;
        window.scrollTo({
          top: currentScroll + 100,
          behavior: "smooth",
          duration: 1000,
        });

        return;
      }

      if (name == "pga") {
        await addToCheckout(type, name, cost);
        //refreshPaypal();

        let currentScroll = window.scrollY;
        window.scrollTo({
          top: currentScroll + 100,
          behavior: "smooth",
          duration: 1000,
        });

        return;
      }
    }

    //other than checkout, add to menu
    let newMenu = { ...menu };
    if (!newMenu[type]) {
      newMenu[type] = [];
    }
    newMenu[type].push({ name, on: true });
    setMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    addToLines(name);
  };

  let makeInactive = async (type, name) => {
    let newMenu = { ...menu };
    newMenu[type] = newMenu[type].filter((a) => a.name != name);
    setMenu(newMenu);
    let toggleRes = await togglePrefSettings("menu", newMenu);
    removeFromLines(name);
  };

  let addToLines = async (name) => {
    if (name == "all") {
      name = "weather";
    }
    console.log("addToLines", name, preferences);
    let line1Data = preferences?.line1Data || [];
    let line2Data = preferences?.line2Data || [];
    console.log("line1Data", line1Data);
    console.log("line2Data", line2Data);

    if (!line1Data?.includes(name)) {
      line1Data.push(name);
    }
    if (!line2Data?.includes(name)) {
      line2Data.push(name);
    }

    //legacy
    /*
    let toggleRes = await togglePrefSettings("line1Data", line1Data);
    let toggleRes2 = await togglePrefSettings("line2Data", line2Data);
    */

    //new
    if (display) {
      Object.keys(display).forEach((key) => {
        let newDisplay1;
        let newDisplay2;

        let dDisplay = display[key] || { line1Data: [], line2Data: [] };
        console.log("dDisplay", dDisplay);

        if (!dDisplay?.line1Data?.includes(name)) {
          dDisplay.line1Data.push(name);
          newDisplay1 = dDisplay.line1Data;

          console.log("send newDisplay1", key, newDisplay1);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = {
              ...updatedDisplay[key],
              line1Data: newDisplay1,
            };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", {
            uid,
            type: "line1Data",
            value: newDisplay1,
            dId: key,
          }).then((res) => {
            console.log("setDiplay line1Data res", res.data);
          });
        }
      });
    }
  };

  let removeFromLines = async (name) => {
    if (name == "all") {
      name = "weather";
    }
    console.log("removeFromLines", name);
    let line1Data = preferences?.line1Data;
    let line2Data = preferences?.line2Data;

    if (line1Data?.includes(name)) {
      line1Data = line1Data?.filter((a) => a != name);
    }
    if (line2Data?.includes(name)) {
      line2Data = line2Data?.filter((a) => a != name);
    }

    //legacy
    let toggleRes = await togglePrefSettings("line1Data", line1Data);
    let toggleRes2 = await togglePrefSettings("line2Data", line2Data);

    //new
    if (display) {
      Object.keys(display).forEach((key) => {
        let newDisplay1;
        let newDisplay2;

        if (display[key].line1Data?.includes(name)) {
          newDisplay1 = display[key].line1Data?.filter((a) => a != name);
          console.log("send newDisplay1", key, newDisplay1);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = {
              ...updatedDisplay[key],
              line1Data: newDisplay1,
            };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", {
            uid,
            type: "line1Data",
            value: newDisplay1,
            dId: key,
          }).then((res) => {
            console.log("setDiplay line1Data res", res.data);
            // Update display locally
          });
        }
        if (display[key].line2Data?.includes(name)) {
          newDisplay2 = display[key].line2Data?.filter((a) => a != name);
          console.log("send newDisplay2", key, newDisplay2);

          setDisplay((prevDisplay) => {
            let updatedDisplay = { ...prevDisplay };
            updatedDisplay[key] = {
              ...updatedDisplay[key],
              line2Data: newDisplay2,
            };
            return updatedDisplay;
          });

          Axios.post("https://quotron.co/display", {
            uid,
            type: "line2Data",
            value: newDisplay2,
            dId: key,
          }).then((res) => {
            console.log("setDiplay line2Data res", res.data);
            // Update display locally
          });
        }
      });
    }
  };

  /*
  let sendMenu = async (newMenu) => {
    var options = {
      method: "POST",
      url: "https://quotron.co/sendMenu",
      data: {
        uid,
        menu: newMenu,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    console.log("sendMenu", options);

    try {
      let res = await Axios.request(options);
      console.log("sendMenu res", res);
    } catch (err) {
      console.log("addToUser err", err);
    }

    console.log("res", res);
  };
  */

  let financeRow = menuTotal?.finance?.map((a, k) => {
    let isActive = false;
    if (menu.finance.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    if (isActive) {
      return (
        <div
          style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }}
          onClick={() => makeInactive("finance", a.name)}
        >
          <div
            className={"profileTextSub"}
            style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
          >
            {a.name}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img
              src={darkmode ? checkImg : checkImgW}
              style={{ height: 20, width: 20, opacity: darkmode ? 1 : 0.9 }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        style={darkmode ? { ...local.s2Dark } : { ...local.s2 }}
        onClick={() => makeActive("finance", a.name)}
      >
        <div
          className={"profileTextSub"}
          style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
        >
          {a.name}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img
            src={darkmode ? plusW : plus}
            style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }}
          />
        </div>
      </div>
    );
  });

  let sportsRow = menuTotal?.sports?.map((a, k) => {
    let isActive = false;
    if (menu.sports.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    if (a.isAdmin && !isAdmin) {
      return null;
    }

    let title = a.name?.toUpperCase();

    let showCost = "";
    if (a.cost) {
      showCost = a.cost?.toFixed(2);
    }

    let isCheckout = !!checkout.find(
      (b) => b.type == "sports" && b.name == a.name
    );

    //console.log("isCheckout", a.name, isCheckout);

    let sideInside = <div />;

    if (isActive) {
      //active
      sideInside = (
        <div
          style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }}
          onClick={() => makeInactive("sports", a.name)}
        >
          <div
            className={"profileTextSub"}
            style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
          >
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img
              src={darkmode ? checkImg : checkImgW}
              style={{ height: 20, width: 20, opacity: darkmode ? 1 : 0.9 }}
            />
          </div>
        </div>
      );
    } else if (isCheckout) {
      //in cart
      sideInside = (
        <div
          style={darkmode ? { ...local.s3Dark } : { ...local.s3 }}
          onClick={() => makeActive("sports", a.name)}
        >
          <div
            className={"profileTextSub"}
            style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
          >
            {title}
          </div>
          {showCost && (
            <div
              className={"profileTextSub"}
              style={{
                display: "flex",
                alignSelf: "center",
                justifySelf: "flex-end",
                color: darkmode ? "#a2a2a2" : "#444",
                fontSize: 16,
                marginRight: 7,
              }}
            >{`$${showCost}/ mo`}</div>
          )}
          <div style={darkmode ? local.shopDark : local.shop}>
            <div
              className="fa fa-shopping-cart"
              style={{
                color: darkmode ? "#333" : "#555",
                fontSize: 15,
              }}
            ></div>
          </div>
        </div>
      );
    } else {
      //not in cart
      sideInside = (
        <div
          style={darkmode ? { ...local.s2Dark } : { ...local.s2 }}
          onClick={() => makeActive("sports", a.name)}
        >
          <div
            className={"profileTextSub"}
            style={{
              flex: 1,
              color: darkmode ? "#bbb" : "#000",
              flexDirection: "row",
              display: "flex",
              height: 40,
              alignItems: "center",
            }}
          >
            {title}
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {!a.bought && !a.preBought && showCost && (
              <div
                className={"profileTextSub"}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifySelf: "flex-end",
                  color: darkmode ? "#a2a2a2" : "#444",
                  fontSize: 16,
                  marginRight: 7,
                }}
              >{`$${showCost}/ mo`}</div>
            )}
            <div style={darkmode ? local.plusDark : local.plus}>
              <img
                src={darkmode ? plusW : plus}
                style={{
                  height: 18,
                  width: 18,
                  opacity: darkmode ? 0.5 : 0.6,
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return <div className={"storeSlide"}>{sideInside}</div>;
  });

  let weatherRow = menuTotal?.weather?.map((a, k) => {
    let isActive = false;
    if (menu.weather.find((b) => b.name == a.name)?.name) {
      isActive = true;
    }

    let title = "weather";

    if (isActive) {
      return (
        <div
          style={darkmode ? { ...local.s2ActiveDark } : { ...local.s2Active }}
          onClick={() => makeInactive("weather", a.name)}
        >
          <div
            className={"profileTextSub"}
            style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
          >
            {title}
          </div>
          <div style={darkmode ? local.checkDark : local.check}>
            <img
              src={darkmode ? checkImg : checkImgW}
              style={{ height: 20, width: 20, opacity: darkmode ? 1 : 0.9 }}
            />
          </div>
        </div>
      );
    }
    return (
      <div
        style={darkmode ? { ...local.s2Dark } : { ...local.s2 }}
        onClick={() => makeActive("weather", a.name)}
      >
        <div
          className={"profileTextSub"}
          style={{ flex: 1, color: darkmode ? "#bbb" : "#000" }}
        >
          {title}
        </div>
        <div style={darkmode ? local.plusDark : local.plus}>
          <img
            src={darkmode ? plusW : plus}
            style={{ height: 18, width: 18, opacity: darkmode ? 0.5 : 0.6 }}
          />
        </div>
      </div>
    );
  });

  let checkoutList = checkout.map((a, k) => {
    let getRow = menuTotal[a.type].find((b) => b.name == a.name);
    let name = getRow?.name;
    let cost = getRow?.cost?.toFixed(2);
    let yearCost = (cost * 12).toFixed(2);
    let yearCostWithDiscount = (cost * 12 * 0.8).toFixed(2);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "stretch",
          alignItems: "flex-end",
          marginBottom: 14,
        }}
      >
        <div
          className={"profileTextSub"}
          style={{
            color: darkmode ? "#fff" : "#fff",
            marginRight: 1,
            fontSize: 19,
            marginBottom: -1,
          }}
        >
          {name}
        </div>

        <div
          style={{
            flex: 1,
            height: 1,
            alignSelf: "stretch",
            borderTop: "1px dotted #777",
            margin: "0 4px",
            alignSelf: "flex-end",
          }}
        />

        <div
          className={"profileTextSub"}
          style={{
            display: "flex",
            alignSelf: "center",
            justifySelf: "flex-end",
            color: "#fff",
            fontSize: 17,
            marginRight: 2,
            alignSelf: "flex-end",
            marginBottom: -2,
          }}
        >
          <span style={{ opacity: 0.6 }}>$</span>
          {billingInterval == "year" ? yearCost : cost}
          <span style={{ opacity: 0.6, marginLeft: 3 }}>
            {billingInterval == "year" ? ` /yr` : ` /mo`}
          </span>
        </div>

        {false && (
          <div
            style={{
              height: 20,
              width: 15,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              marginLeft: 4,
            }}
            onClick={() => removeFromCheckout(a.type, a.name)}
          >
            <img
              src={darkmode ? closeX : closeXDark}
              style={{ height: 15, width: 15, opacity: darkmode ? 0.5 : 0.5 }}
            />
          </div>
        )}
      </div>
    );
  });

  const stripePromise = loadStripe(
    "pk_test_51QkDfFFR9364i6MdIjHsiIGMYn4Dhu4c4lxGKpFLDgVmVnGNaWA6Wz8l7BJptESCyBUhbWF06AmjSgY98FlTpVYk00busqPmqt"
  );

  const SubscriptionButton2 = () => {
    const [loading, setLoading] = useState(false);

    const handleSubscribe = async () => {
      setLoading(true);

      console.log("SubscriptionButton2 - checkout", checkout);
      let items = [];

      let priceIds = [
        {
          cost: 5, //tier2 annual
          billingInterval: "year",
          priceId: "price_1QkMxQFR9364i6Mdu5Dc0q8L",
        },
        {
          cost: 5, //tier2 monthly
          billingInterval: "month",
          priceId: "price_1QkMwzFR9364i6Mdp2NuNRZ4",
        },
        {
          cost: 2.5, //tier1 annual
          billingInterval: "year",
          priceId: "price_1QkMp8FR9364i6Mdgab3VT6P",
        },
        {
          cost: 2.5, //tier1 monthly
          billingInterval: "month",
          priceId: "price_1QkMnDFR9364i6MdyaKxDyzs",
        },
      ];

      checkout.forEach((feed) => {
        if (feed.cost === 5) {
          items.push({
            priceId: priceIds.find(
              (p) => p.cost == 5 && p.billingInterval == billingInterval
            )?.priceId,
            quantity: 1,
          });
        } else if (feed.cost === 2.5) {
          items.push({
            priceId: priceIds.find(
              (p) => p.cost == 2.5 && p.billingInterval == billingInterval
            )?.priceId,
            quantity: 1,
          });
        }
      });

      //price_1QkMp8FR9364i6Mdgab3VT6P

      console.log("SubscriptionButton2 - items", items);

      const response = await Axios.post("https://quotron.co/checkout", {
        uid,
        items,
        checkout,
        billingInterval,
      });

      const { sessionId } = response.data;
      console.log("redirectToCheckout");
      console.log("sessionId", sessionId);

      const stripe = await stripePromise;

      await stripe.redirectToCheckout({ sessionId });

      setLoading(false);
    };

    return (
      <button
        onClick={handleSubscribe}
        disabled={loading}
        style={{
          ...local.subscribe,
          backgroundColor: "#89d5c2", //"#00d28d", //"#00B87C", // 635BFF
          color: "#000", //"#fff",
          padding: "0 15px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          fontSize: 17, // Increased from default 15px
          height: 48, // Increased from 40px defined in local.subscribe
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 5.5h-2.5V4a3 3 0 0 0-3-3h-1a3 3 0 0 0-3 3v1.5H2c-.6 0-1 .4-1 1V12c0 1.7 1.3 3 3 3h8c1.7 0 3-1.3 3-3V6.5c0-.6-.4-1-1-1zM6.5 4c0-.8.7-1.5 1.5-1.5h1c.8 0 1.5.7 1.5 1.5v1.5h-4V4z"
            stroke="black"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {loading ? "Loading..." : "Pay with Stripe"}
      </button>
    );
  };

  let itemsNum = checkout.length;
  let total = checkout.reduce((sum, item) => {
    console.log("item", item);
    if (item.name === "nippon") return sum + 2.5;
    if (item.name === "pga") return sum + 5.0;
    return sum;
  }, 0);

  let yearTotal = checkout.reduce((sum, item) => {
    if (item.name === "nippon") return sum + 2.5 * 12 * 0.8;
    if (item.name === "pga") return sum + 5.0 * 12 * 0.8;
    return sum;
  }, 0);

  let yearTotalNoDiscount = checkout.reduce((sum, item) => {
    if (item.name === "nippon") return sum + 2.5 * 12;
    if (item.name === "pga") return sum + 5.0 * 12;
    return sum;
  }, 0);

  let discountAmount = yearTotalNoDiscount - yearTotal;

  let showPop = `${itemsNum} Item${itemsNum > 1 ? "s" : ""} - ($${total.toFixed(
    2
  )}/mo)`;
  //showPop = "";

  return (
    <div
      className={"prefMenu"}
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      {showSuccess == "loading" && (
        <div
          style={{
            minWidth: 350,
            border: "0px solid #bbb",
            borderTopWidth: 1,
            marginTop: 10,
            alignSelf: "stretch",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: 20,
            flexDirection: "column",
          }}
        >
          <div className={"loader"} style={{ marginLeft: 2, marginTop: 6 }} />
        </div>
      )}
      {showSuccess == "completed" && (
        <div
          style={{
            alignSelf: "stretch",
            //backgroundColor: "#89d5c2",
            border: "0px solid #bbb",
            borderTopWidth: 1,
            marginTop: 10,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            padding: 1,
          }}
        >
          <div
            className={"profileTextSub"}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: darkmode ? "#ddd" : "#222",
                marginBottom: 8,
                marginTop: 20,
              }}
            >
              success!
            </div>
            <div
              style={{
                color: darkmode ? "#ddd" : "#222",
                display: "flex",
              }}
            >
              {false && <div style={{ width: 80 }}>PGA</div>}
              <div style={{ opacity: darkmode ? 0.7 : 0.6 }}>activated</div>
            </div>
            {false && (
              <div
                style={{
                  color: darkmode ? "#ddd" : "#222",
                  display: "flex",
                }}
              >
                <div style={{ width: 80 }}>Nippon</div>
                <div style={{ opacity: darkmode ? 0.7 : 0.6 }}>activated</div>
              </div>
            )}
          </div>
        </div>
      )}
      {showSuccess != "loading" && (
        <div style={local.box}>
          <div style={local.header}>
            <div
              style={{ ...local.header2, color: darkmode ? "#aaa" : "#000" }}
            >{`catagories`}</div>
          </div>
          <div style={{ ...local.s1 }}>{"finance"}</div>
          {financeRow}
          <div style={{ ...local.s1 }}>{"sports"}</div>
          {sportsRow}
          <div style={{ ...local.s1 }}>{"weather"}</div>
          {weatherRow}
        </div>
      )}

      {/*store pop*/}
      <>
        <div
          onClick={() => !showCheckout && setShowCheckout(true)}
          style={{
            ...local.popShop,
            height: showCheckout ? `${window.innerHeight - 64}px` : "64px",
            transition: "all 0.5s ease-in-out",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: 20,
            paddingTop: 18,
            maxWidth: 500,
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: darkmode ? "#333" : "#444",
            border: "0px solid #555",
            borderTopWidth: 1,
            transform: `translateY(${checkout.length <= 0 ? 70 : 0}px)`,
          }}
        >
          {/* pop shop small  */}
          {!showCheckout && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                opacity: 0.9,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#89d5c2",
                  marginTop: -20,
                  height: 70,
                  paddingTop: 1,
                  paddingRight: 20,
                  marginRight: -20,
                  marginLeft: -20,
                  flex: 1,
                  paddingLeft: 20,
                  paddingRight: 20,
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: -4,
                  }}
                >
                  <div style={{ marginRight: 7, marginTop: 1 }}>
                    <i
                      className="fa fa-shopping-cart"
                      style={{
                        color: darkmode ? "#000" : "#444",
                        opacity: darkmode ? 0.7 : 1,
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div
                    className={"profileTextSub"}
                    style={{ color: "#000", fontSize: 18 }}
                  >{`(${itemsNum}) checkout `}</div>
                </div>

                <div>
                  <i
                    className="fa fa-arrow-right"
                    style={{
                      color: darkmode ? "#000" : "#444",
                      opacity: darkmode ? 0.7 : 1,
                    }}
                    aria-hidden="true"
                  ></i>
                </div>
                {/*arrow*/}
                {false && (
                  <div
                    style={{
                      marginLeft: 10,
                      backgroundColor: "#89d5c2",
                      width: 24,
                      height: 60,
                      marginTop: -20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      className="fa fa-arrow-right"
                      style={{ color: "#000" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* pop shop title - for big */}
          <div
            onClick={() => setShowCheckout(false)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-between",
              alignSelf: "stretch",
              paddingTop: 10,
            }}
          >
            <div style={{ marginRight: 8, marginTop: 14 }}>
              <i
                className="fa fa-shopping-cart"
                style={{ color: "#fff", opacity: 0.6 }}
                aria-hidden="true"
              ></i>
            </div>
            <div
              style={{
                color: "#fff",
                fontSize: 20,
                paddingTop: 14,
                opacity: 0.8,
              }}
            >{` (${itemsNum}) checkout`}</div>
            <div
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                height: 40,
                width: 40,
                alignItems: "center",
                justifyContent: "flex-end",
                display: "flex",
                flexDirection: "row",
                opacity: 0.7,
                paddingTop: 6,
              }}
              onClick={() => setShowCheckout(false)}
            >
              <img
                src={darkmode ? closeXDark : closeXDark}
                style={{ width: 18, height: 18 }}
                alt="close"
              />
            </div>
          </div>

          {/* pop shop big */}
          {showCheckout && (
            <div
              style={{
                overflow: "auto",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <style>
                {`
                    @keyframes fadeIn {
                      from { opacity: 0; }
                      to { opacity: 0.7; }
                    }
                  `}
              </style>
              <div
                style={{
                  height: 0.5,
                  backgroundColor: "#666",
                  alignSelf: "center",
                  width: 32,
                  marginTop: 30,
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "stretch",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginTop: 24 }}>{checkoutList}</div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className={"profileTextSub"}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      onClick={() =>
                        setBillingInterval(
                          billingInterval === "month" ? "year" : "month"
                        )
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 6,
                        paddingTop: 0,
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          color: "#ddd",
                          fontSize: 16,
                        }}
                      >
                        {`Monthly`}
                      </span>
                      <Switch
                        style={{
                          height: 18,
                          width: 28,
                          marginTop: 2,
                          //opacity: 0.7,
                          // #00B87C
                        }}
                        styles={{
                          track: {
                            backgroundColor: "#ccc",
                          },
                          trackChecked: {
                            backgroundColor: "#99ace0",
                          },
                        }}
                        onChange={(value) =>
                          setBillingInterval(value ? "year" : "month")
                        }
                        value={billingInterval === "year" ? 1 : 0}
                      />
                      <span
                        style={{
                          color: "#ddd",
                          fontSize: 16,
                        }}
                      >
                        {`Yearly (-20%)`}
                      </span>
                    </div>

                    <div
                      style={{
                        fontSize: 17,
                        color: "#fff",
                        marginRight: 4,
                        position: "relative",
                      }}
                    >
                      <span style={{ opacity: 0.6 }}>$</span>
                      <span style={{ color: "#fff" }}>
                        {billingInterval == "year"
                          ? yearTotalNoDiscount.toFixed(2)
                          : total.toFixed(2)}
                      </span>
                      <span style={{ opacity: 0.6 }}>
                        {billingInterval == "year" ? " /yr" : " /mo"}
                      </span>
                      {false && billingInterval == "year" && (
                        <div
                          style={{
                            position: "absolute",
                            right: 11,
                            bottom: -21,
                            fontSize: 12,
                            fontSize: 16,
                            opacity: 0.6,
                          }}
                        >
                          {`-20 %`}
                        </div>
                      )}
                    </div>
                  </div>
                  {billingInterval == "year" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: 3,
                      }}
                    >
                      <div
                        style={{
                          color: "#fff",
                          fontSize: 17,
                          opacity: 0.7,
                          marginRight: 4,
                        }}
                      >
                        <span style={{ opacity: 0.3 }}>{"["}</span>-20%
                        <span style={{ opacity: 0.3 }}>{"]"}</span>
                        <span style={{ marginRight: 12 }} />
                        {`-${discountAmount.toFixed(2)} /yr`}
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 20,
                    }}
                    className={"profileTextSub"}
                  >
                    <div />
                    <div
                      style={{
                        color: "#fff",
                        fontSize: 17,
                        padding: 4,
                        paddingLeft: 40,
                        border: "0px solid #555",
                        borderTopWidth: 0.5,
                        paddingTop: 20,
                        paddingBottom: 24,
                      }}
                    >
                      total:{" "}
                      <span
                        style={{
                          opacity: 0.6,
                          marginRight: -1,
                          marginLeft: 4,
                        }}
                      >
                        $
                      </span>
                      {billingInterval == "year"
                        ? yearTotal.toFixed(2)
                        : total.toFixed(2)}
                      <span style={{ opacity: 0.6 }}>
                        {billingInterval == "year" ? " /yr" : " /mo"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "stretch",
                  opacity: 0,
                  animation: "fadeIn 0.5s ease-in forwards",
                  animationDelay: "0.7s",
                }}
              >
                <Elements stripe={stripePromise}>
                  <SubscriptionButton2 />
                </Elements>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

let local = {
  box: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 100,
    width: 356,
  },
  header: {
    alignSelf: "stretch",
    border: "#aaa solid 0px",
    borderBottomWidth: 1,
    marginBottom: 6,
    marginRight: 10,
  },
  header2: {
    marginTop: 20,
    marginBottom: -1,
    paddingLeft: 4,
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    color: "#999",
    width: 50,
    marginLeft: 2,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    alignSelf: "flex-start",
    marginBottom: 8,
  },
  s1: {
    height: 40,
    backgroundColor: "#555",
    color: "#fff",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "baseline",
    paddingLeft: 6,
    paddingRight: 14,
    marginTop: 10,
  },
  s2: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2Dark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    //width: 270,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  s3: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 289,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s3Dark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 289,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  s2Active: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #ccc",
    marginTop: -1,
  },
  s2ActiveDark: {
    height: 40,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    width: 200,
    paddingLeft: 6,
    border: "1px solid #444",
    marginTop: -1,
  },
  check: {
    height: 40,
    border: "1px solid #ccc",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  checkDark: {
    height: 40,
    border: "1px solid #444",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#637dc8",
  },
  plus: {
    height: 40,
    border: "0px solid #ddd",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
  plusDark: {
    height: 40,
    border: "0px solid #444",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
  },
  shop: {
    height: 40,
    border: "0px solid #ddd",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#89d5c2",
  },
  shopDark: {
    height: 40,
    border: "0px solid #444",
    borderLeftWidth: 1,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#999",
    backgroundColor: "#89d5c2",
  },
  popShop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    right: 0,
    left: 0,
    paddingRight: 20,
    paddingLeft: 20,
    bottom: 0,
    height: 70,
    width: "100%",
    zIndex: 1000,
  },
  bigCheckout: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#444",
    zIndex: 1000,
  },
  subscribe: {
    height: 40,
    backgroundColor: "#89d5c2",
    color: "#000",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    borderRadius: 4,
    fontSize: 15,
  },
};

export default Store;

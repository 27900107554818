import React, { useEffect, useState } from "react";
import arrowLeft from "../imgs/arrowLeft2.png";
import arrowLeftW from "../imgs/arrowLeftW.png";

import Axios from "axios";

function PrefMenu(props) {
  let [canSleep, setCanSleep] = useState(false);
  let [has1p, setHas1p] = useState(false);
  let {
    uid,
    darkmode,
    isAdmin,
    isTrail,
    screenWidth,
    isMobile,
    preferences,
    deviceArr,
    loaded,
  } = props;

  let activeMenu = darkmode ? "prefMenuItemBlue" : "prefMenuItemDark";
  let inainactiveMenu = darkmode ? "prefMenuItemLight" : "prefMenuItem";

  let checkVer = (baseVersion, queryVersion) => {
    const baseParts = baseVersion.split(".").map(Number);
    const queryParts = queryVersion.split(".").map(Number);

    for (let i = 0; i < 3; i++) {
      if (queryParts[i] > baseParts[i]) {
        return true;
      } else if (queryParts[i] < baseParts[i]) {
        return false;
      }
    }
    return true; // Versions are equal
  };

  let checkDeviceVer = async () => {
    deviceArr?.map(async (a) => {
      if (a?.panels?.includes("1x1")) {
        setHas1p(true);
      }

      let sku = await Axios.get(`https://quotron.co/getSku/${a.id}`).then(
        (res) => res.data
      );
      if (sku?.type) {
        setHas1p(true);
      }

      Axios.get(`https://quotron.co/deviceVer/${a.id}/${props.uid}`).then(
        (res) => {
          //console.log("res.data", res.data);

          if (res?.data?.versionArr) {
            let vArr = res?.data?.versionArr;
            //console.log("vArr", vArr);
            let thisVer = vArr[vArr.length - 1];
            if (checkVer("2.14.5", thisVer)) {
              setCanSleep(true);
            } else {
              //  console.log("nop");
            }
          }
        }
      );
    });
  };

  useEffect(() => {
    console.log("prefMenu", preferences);
    if (deviceArr?.length > 0) {
      checkDeviceVer();
    }
  }, [deviceArr]);

  let menu = preferences?.menu;
  if (!menu) {
    menu = {
      finance: [
        { name: "stocks", on: true },
        { name: "crypto", on: true },
        { name: "markets", on: true },
        { name: "forex", on: true },
        { name: "comms", on: true },
      ],
      sports: [
        { name: "mlb", on: true },
        { name: "nfl", on: true },
        { name: "nba", on: true },
        { name: "nhl", on: true },
        { name: "ncaa", on: true },
      ],
      weather: [{ name: "all", on: true }],
      //custom: [],
      //news: [],
    };
  }
  /*
    //news
    {name: 'general', on: false}
    {name: 'finance', on: false}
    {name: 'forex', on: false}
    {name: 'crypto', on: false}
  */

  //one way to turn on +store
  if (uid == "YFKtXew5veMdpm97mWYZHnZzy9F2") {
    isAdmin = true;
  }
  if (isTrail || has1p) {
    isAdmin = true;
  }

  // Loop through top-level fields, filter arrays for { on: true }, and remove empty fields
  const filteredMenu = Object.keys(menu || {})?.reduce((acc, key) => {
    const filteredArray = (menu[key] || [])?.filter((item) => item.on === true);

    // Only add the key if the filtered array is not empty
    if (filteredArray.length > 0) {
      acc[key] = filteredArray;
    }

    return acc;
  }, {});

  let onlyLower = false;
  //if menu has only 1 major field, and inside of it has only 1 array item, then onlyLower = true
  if (
    Object.keys(filteredMenu).length === 1 &&
    filteredMenu[Object.keys(filteredMenu)[0]].length === 1
  ) {
    onlyLower = true;
  }

  //if the filtered menu is just weather, then onlyLower = false
  if (
    Object.keys(filteredMenu).length === 1 &&
    filteredMenu[Object.keys(filteredMenu)[0]].length === 1 &&
    Object.keys(filteredMenu)[0] === "weather"
  ) {
    onlyLower = false;
  }

  let upperRowArrOp = [
    { name: "finance", isAdmin: false },
    { name: "sports", isAdmin: false },
    //{ name: "news", isAdmin: true },
    { name: "weather", isAdmin: false },
    //custom
  ];

  /*finance */
  let upperRowArr = Object.keys(filteredMenu)?.map((a) => {
    return upperRowArrOp?.filter((b) => b.name == a)[0] || [];
  });
  upperRowArr.push({ name: "store", isAdmin: true });

  //diplay upper row
  let upperRow = upperRowArr?.map((a, k) => {
    if (!a) return;
    if (a.isAdmin && !isAdmin) return;

    let displayName = <div>{a.display || a.name}</div>;
    if (a.name == "store") {
      displayName = (
        <div style={{ width: 26, fontSize: 22, paddingLeft: 7, paddingTop: 0 }}>
          {"+"}
        </div>
      );
    }

    return (
      <div
        className={
          props.activePrefUpper == a.name ? activeMenu : inainactiveMenu
        }
        onClick={() => props.togglePrefUpper(a.name)}
      >
        {displayName}
      </div>
    );
  });

  let financeRowArrOp = [
    { name: "stocks", isAdmin: false },
    { name: "crypto", isAdmin: false },
    { name: "markets", isAdmin: false },
    { name: "forex", isAdmin: false },
    { name: "comms", isAdmin: false, display: "comm." },
  ];

  //sub1 finance
  let financeRowArr = filteredMenu?.finance?.map((a) => {
    return financeRowArrOp?.filter((b) => b.name == a.name)[0] || [];
  });

  let financeRow = financeRowArr?.map((a, k) => {
    if (!a) return;
    if (a.isAdmin && !isAdmin) return;
    let showName = a.name;

    return (
      <div
        className={props.activePref == a.name ? activeMenu : inainactiveMenu}
        onClick={() => props.togglePref(a.name)}
      >
        {showName}
      </div>
    );
  });

  //sub2 sports
  let sportRowArrOp = [
    { name: "mlb", display: "MLB", active: true, isAdmin: false },
    { name: "nfl", display: "NFL", active: true, isAdmin: false },
    { name: "nhl", display: "NHL", active: true, isAdmin: false },
    { name: "ncaa", display: "NCAA", active: true, isAdmin: false },
    { name: "nba", display: "NBA", active: true, isAdmin: false },
    //golf, soccer
  ];

  //sub1 finance
  let sportRowArr = filteredMenu?.sports?.map((a) => {
    return sportRowArrOp?.filter((b) => b.name == a.name)[0] || [];
  });

  //ncaa last
  // sportRowArr = sportRowArr?.filter((a) => a.name !== "ncaa")?.concat(sportRowArr?.filter((a) => a.name === "ncaa"));

  //sort by active
  sportRowArr = sportRowArr?.sort((a, b) => b.active - a.active);

  let sportsRow = sportRowArr?.map((a, k) => {
    if (a.isAdmin && !isAdmin) return;
    let isActive = props.activePref == a.name;
    if (a.name == "ncaa") {
      if (
        props.activePref == "ncaaF" ||
        props.activePref == "ncaaB" ||
        props.activePref == "ncaaH"
      ) {
        isActive = true;
      }
    }
    let goTo = a.name;
    if (goTo == "ncaa") {
      goTo = "ncaaF";
    }
    return (
      <div
        className={isActive ? activeMenu : inainactiveMenu}
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          fontSize: 17,
          opacity: a.active ? 1 : 0.3,
        }}
        onClick={() => props.togglePref(goTo)}
      >
        {a.display || a.name}
      </div>
    );
  });

  //setings top row
  let settingsRowArr = [
    { name: "devices", active: true, isAdmin: false },
    { name: "clock", active: true, isAdmin: false },
    { name: "custom", active: true, isAdmin: false },
  ];
  /*
  if (!canSleep) {
    settingsRowArr = settingsRowArr.filter((a) => a.name != "sleep");
  }
    */

  let settingsRow = settingsRowArr?.map((a, k) => {
    if (a.isAdmin && !isAdmin) return;
    let goTo = a.name;
    if (goTo == "settings") {
      goTo = "devices";
    }
    let showName = a.name;
    if (showName == "devices") {
      showName = "display";
    }
    return (
      <div
        className={props.activePref == a.name ? activeMenu : inainactiveMenu}
        onClick={() => props.togglePref(goTo)}
      >
        {showName}
      </div>
    );
  });

  let small = {
    maxWidth: screenWidth + 5,
    marginLeft: -12,
    marginRight: -12,
    paddingLeft: 12,
  };
  let big = {
    width: screenWidth,
    alignSelf: "stretch",
    justifyContent: "flex-start",
    paddingLeft: screenWidth / 2 - 180,
  };

  let dynamic = isMobile ? small : big;

  let lowerPlus = (
    <div
      className={
        props.activePrefUpper == "store" ? activeMenu : inainactiveMenu
      }
      style={{ height: 38 }}
      onClick={() => props.togglePrefUpper("store")}
    >
      <div style={{ width: 26, fontSize: 22, paddingLeft: 7, paddingTop: 0 }}>
        {"+"}
      </div>
    </div>
  );

  let showFinance =
    (props.activePrefUpper == "finance" || props.activePrefUpper == "store") &&
    props.activePref != "admin";
  let showSports =
    (props.activePrefUpper == "sports" || props.activePrefUpper == "store") &&
    props.activePref != "admin";

  if (!loaded) {
    return <div style={{ height: 40 }} />;
  }

  let showLowerStore = true;

  if (props.activePrefUpper == "store" && !onlyLower) {
    showLowerStore = false;
  }
  if (props.activePrefUpper == "settings") {
    showLowerStore = true;
  }

  let marginBot = props.activePrefUpper == "store" ? 2 : 8;

  let returnBack = () => {
    let filteredMenuKeys = Object.keys(props.preferences.menu).filter(
      (key) => props.preferences.menu[key].length > 0
    );
    let upper = filteredMenuKeys[0];
    let lower = props.preferences.menu[upper]?.[0]?.name;
    if (!upper) {
      upper = "store";
      lower = "store";
    }
    props.togglePrefUpper(upper);
    props.togglePref(lower);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
        marginTop: props.isMobile ? 0 : -10,
      }}
    >
      {props.activePrefUpper != "settings" && !onlyLower && (
        <div
          className={"prefMenuUpper hideScroll"}
          style={{
            ...dynamic,
          }}
        >
          {upperRow}
        </div>
      )}

      {showLowerStore && (
        <div className={"prefMenuLower hideScroll"} style={{ ...dynamic }}>
          {showFinance && (
            <div className={"prefMenu"} style={{ marginBottom: marginBot }}>
              {financeRow}
            </div>
          )}
          {showSports && (
            <div className={"prefMenu"} style={{ marginBottom: marginBot }}>
              {sportsRow}
            </div>
          )}
          {onlyLower &&
            props.activePrefUpper != "settings" &&
            isAdmin &&
            lowerPlus}
          {props.activePrefUpper == "settings" && props.activePref != "admin" && (
            <div className={"prefMenu"}>
              <div
                onClick={() => returnBack()}
                style={{
                  marginTop: 2,
                  height: 37,
                  width: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: darkmode ? "1px solid #444" : "1px solid #aaa",
                  borderRadius: 2,
                  marginRight: 2,
                }}
              >
                <img
                  src={darkmode ? arrowLeftW : arrowLeft}
                  style={{ height: 15, width: 15 }}
                />
              </div>

              {settingsRow}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PrefMenu;

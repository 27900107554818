import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import closeXWhite from "../../imgs/closeXWhite.png";
import RssButton from "./RssButton";
import { marketsList } from "../../util/helpers";

let ListMarkets = (props) => {
  let {
    markets,
    activeMarket,
    hiddenMarkets,
    darkmode,
    isMobile,
    preferences,
  } = props;
  let marketData = markets || [];

  //temp - moex
  marketData = marketData?.filter((a) => a.symbol != "IMOEX.ME");

  let marketsArr = marketsList();
  marketData = marketData.map((a) => {
    let temp = marketsArr.filter((b) => b.symbol == a.symbol)[0];
    return {
      ...a,
      appName: temp?.appName || a.name,
      group: temp?.group,
      pos: temp?.pos,
    };
  });

  marketData = marketData.sort((a, b) => {
    if (a.group === b.group) {
      return a.pos - b.pos;
    }
    return a.group - b.group;
  });

  let showFavs = (group = 1) => {
    return marketData.map((a, k) => {
      if (a.group != group) return null;
      let weatherButtonCss = darkmode ? "favListItemDark" : "favListItem";
      if (activeMarket == k) {
        weatherButtonCss = darkmode
          ? "favListItemDarkActive"
          : "favListItemActive";
      }
      let isHidden = false;
      if (hiddenMarkets.includes(a.symbol?.replace(".", "-"))) {
        isHidden = true;
        /*
      if (activeMarket == k) {
        weatherButtonCss = "favListItemActive favListItemHidden";
      } else {
        weatherButtonCss = "favListItem favListItemHidden";
      }
      */
      }

      //console.log(a);

      let showPrice = a.price?.toFixed(2);
      let showChange = a.change?.toFixed(2);
      let upDown = preferences.upDown || "percentage";
      if (upDown == "percentage") {
        showChange = a.changesPercentage?.toFixed(2) + "%";
      }
      //let rawChange = a.regularMarketChangePercent.raw;

      let changeColor = darkmode ? "#5fff5f" : "#0c870c";
      let showCaret = "fa fa-caret-up";
      if (activeMarket == k) {
        changeColor = "#5fff5f";
      }
      if (a.change < 0) {
        changeColor = darkmode ? "#ef0101" : "#bd0101";
        showCaret = "fa fa-caret-down";
        if (activeMarket == k) {
          changeColor = darkmode ? "#ef0101" : "#ffbe9e";
        }
      }

      let shortcutSymbol = a.symbol;
      let showSymbol = a.symbol;
      let showName = a?.appName || a.name;
      if (a.symbol == "000001.SS") {
        showSymbol = "SSE";
      }
      if (showName == "XCSE:OMX Stockholm 30 Index") {
        showName = "OMX Stockholm 30";
      }
      if (showName == "S&P/TSX Composite index") {
        showName = "TSX Composite";
      }
      if (showName == "US Debt") {
        showChange = "";
        showSymbol = "DEBT";
        showPrice = a.ext || showPrice;
      }

      //undefined apis
      if (showChange == "undefined%") {
        showChange = "";
        showCaret = "";
      }

      return (
        <div
          onClick={() =>
            isMobile && props.inactiveMarket(shortcutSymbol, isHidden)
          }
          key={k}
          className={"favListBox"}
        >
          <button className={weatherButtonCss} style={{ width: 330 }}>
            <div className={"favListLeft"}>
              <div style={{ minWidth: 36 }}>
                <RssButton
                  onClick={() =>
                    !isMobile && props.inactiveMarket(shortcutSymbol, isHidden)
                  }
                  activeRow={true}
                  inactive={isHidden}
                  darkmode={darkmode}
                />
              </div>
              <div className={"favListSymbol"}>{showName}</div>
              <div className={"favListName"}>{showSymbol}</div>
            </div>
            <div className={"favListRight"}>
              <div
                className={`${showCaret} favItemCaret`}
                style={{ color: changeColor }}
              ></div>
              <div className={"favItemChange"} style={{ color: changeColor }}>
                {`${showChange}`}
              </div>
              <div className={"favItemPrice"}>{showPrice}</div>
            </div>
          </button>
        </div>
      );
    });
  };

  return (
    <div className={darkmode ? "prefBoxDark" : "prefBox"}>
      <div
        className={"profileTextSub"}
        style={{ alignSelf: "stretch", marginLeft: 3, marginBottom: 2 }}
      >
        US Markets
      </div>
      <div className={"favList"} style={{ marginTop: 0 }}>
        {showFavs(1)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div className={"favList"} style={{ marginTop: 2 }}>
        {showFavs(3)}
      </div>

      <div style={{ opacity: 0.5 }}>--</div>
      <div
        className={"profileTextSub"}
        style={{
          alignSelf: "stretch",
          marginLeft: 3,
          marginBottom: 2,
          marginTop: -10,
        }}
      >
        Futures / Continuous
      </div>
      <div className={"favList"} style={{ marginTop: 2 }}>
        {showFavs(2)}
      </div>

      <div style={{ opacity: 0.5 }}>--</div>
      <div
        className={"profileTextSub"}
        style={{
          alignSelf: "stretch",
          marginLeft: 3,
          marginBottom: 2,
          marginTop: -10,
        }}
      >
        Asia
      </div>
      <div className={"favList"} style={{ marginTop: 2 }}>
        {showFavs(4)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div
        className={"profileTextSub"}
        style={{
          alignSelf: "stretch",
          marginLeft: 3,
          marginBottom: 2,
          marginTop: -10,
        }}
      >
        Europe
      </div>
      <div className={"favList"} style={{ marginTop: 2 }}>
        {showFavs(5)}
      </div>
      <div style={{ opacity: 0.5 }}>--</div>
      <div
        className={"profileTextSub"}
        style={{
          alignSelf: "stretch",
          marginLeft: 3,
          marginBottom: 2,
          marginTop: -10,
        }}
      >
        International
      </div>
      <div className={"favList"} style={{ marginTop: 2 }}>
        {showFavs(6)}
      </div>
    </div>
  );
};

export default ListMarkets;

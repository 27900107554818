import React, { Component } from "react";
import Axios from "axios";
import Switch from "react-input-switch";
import { getDatabase, ref, set } from "firebase/database";
import { nflTeams, nbaTeams, nhlTeams, mlbTeams } from "../../util/helpers";
import plus from "../../imgs/plus.png";
import closeX from "../../imgs/closeX.png";
import RssButton from "./RssButton";
import UpcomingSlider from "./UpcomingSlider";
import { dayOfWeek } from "../../util/helpers";

class ListSport extends Component {
  constructor(props) {
    super(props);
    let teamsArr = [];
    if (props.sport == "football") {
      teamsArr = nflTeams();
    }
    if (props.sport == "basketball") {
      teamsArr = nbaTeams();
    }
    if (props.sport == "hockey") {
      teamsArr = nhlTeams();
    }
    if (props.sport == "baseball") {
      teamsArr = mlbTeams();
    }
    if (props.sport == "NCAAF") {
      //teamsArr = nflTeams();
    }
    if (props.sport == "NCAAB") {
      //teamsArr = nflTeams();
    }
    if (props.sport == "NCAAH") {
      //teamsArr = nflTeams();
    }
    teamsArr = this.abcTeams(teamsArr);

    let settingsName = `allGames-${props.name}`;
    let allGames = props.preferences[settingsName] || 0;
    let daysOut = 7;
    if (props.preferences?.daysOut) {
      daysOut = props.preferences?.daysOut[props.name] || 7;
    }

    let up = false;
    if (props.preferences?.daysOut && props.preferences?.daysOut[props.name]) {
      up = true;
    }

    console.log("sports-uid", props.uid, daysOut);

    this.state = {
      gamesArr: [],
      teamsArr,
      showAll: true,
      sport: props.sport || "football",
      hover: -1,
      showMore: false,
      menu: "games",
      allGames,
      teamInput: "",
      liveInput: "",
      upSlider: daysOut,
      up,
      fast: daysOut,
      loaded: false,
    };
  }

  componentDidMount() {
    this.getGames(this.state.sport);
    if (this.props.sport == "NCAAF" || this.props.sport == "NCAAB") {
      this.getNCAATeams(this.props.sport);
    }
  }

  componentDidUpdate(prevProps) {
    let settingsName = `allGames-${this.props.name}`;
    let allGames = this.props.preferences[settingsName];
    let prevAllGames = prevProps.preferences[settingsName];
    if (allGames != prevAllGames) {
      this.setState({ allGames });
    }
  }

  getNCAATeams = async (sport = "NCAAF") => {
    let urlSport = "ncaaF";
    if (sport == "NCAAB") {
      urlSport = "ncaaB";
    }
    var options = {
      method: "GET",
      url: "https://quotron.co/" + urlSport,
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options).then((res) => {
      let teamsArr = res.data?.teams;
      teamsArr = this.abcTeams(teamsArr);
      this.setState({ teamsArr });
    });
  };

  abcTeams = (arr) => {
    return arr.sort((a, b) => a.name.localeCompare(b.name));
  };

  toggleUp = () => {
    let { name, uid } = this.props;
    if (this.state.up) {
      //turn off
      var options = {
        method: "POST",
        url: "https://quotron.co/daysOut",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          uid,
          slider: false,
          name,
        },
      };

      Axios.request(options)
        .then((res) => {
          console.log("daysOut res", res.data);
        })
        .catch((err) => {
          console.log("favTeams err", err);
        });
    } else {
      //turns on,
      this.setState({ upSlider: 7 });
      var options = {
        method: "POST",
        url: "https://quotron.co/daysOut",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          uid,
          slider: 7,
          name,
        },
      };

      Axios.request(options)
        .then((res) => {
          console.log("daysOut res", res.data);
        })
        .catch((err) => {
          console.log("favTeams err", err);
        });
    }
    this.setState({ up: !this.state.up });
  };

  getGames = async (sport = "football") => {
    var options = {
      method: "GET",
      url: "https://quotron.co/getGames/" + sport,
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options).then((res) => {
      let gamesArr = res.data;
      //only
      if (this.state.sport == "football") {
        gamesArr = gamesArr.filter(
          (a) =>
            a.competition?.sport?.slug == "american-football" ||
            a.competition?.slug == "nfl"
        );
      }
      console.log("gamesArr", this.state.sport, gamesArr);
      this.setState({ gamesArr, loaded: true });
    });
  };

  setSlider = (num) => {
    let { name, uid } = this.props;

    this.setState({ upSlider: num });

    console.log("setSlider", num, name, uid);

    var options = {
      method: "POST",
      url: "https://quotron.co/daysOut",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid,
        slider: num,
        name,
      },
    };

    Axios.request(options)
      .then((res) => {
        console.log("daysOut res", res.data);
      })
      .catch((err) => {
        console.log("favTeams err", err);
      });
  };

  getShort = (slug, name) => {
    let { teamsArr } = this.state;
    return (
      teamsArr.filter((a) => a.slug == slug || a.name == name)[0]?.short || ""
    );
  };

  showAllToggle = () => {
    let { showAll } = this.state;
    this.setState({ showAll: !showAll });
  };

  toggleTeam = async (onOff, teamName, slug) => {
    let { name, uid, favTeams, updateFavTeams } = this.props;

    let before = favTeams;

    let isThere = false;
    let favAfter = favTeams?.map((a) => {
      if (a.team == slug) {
        isThere = true;
        return { ...a, onOff: !onOff };
      } else {
        return a;
      }
    });
    if (!isThere) {
      favAfter = favAfter.concat([{ onOff: !onOff, team: slug }]);
    }

    updateFavTeams(favAfter, name);

    var options = {
      method: "POST",
      url: "https://quotron.co/favTeams/",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        uid,
        sport: name,
        team: slug,
        onOff: !onOff,
      },
    };

    Axios.request(options)
      .then((res) => {
        //console.log("favTeams res", res.data);
      })
      .catch((err) => {
        console.log("favTeams err", err);
        updateFavTeams(before, name);
      });
  };

  toggleGame = (id, activeWatch) => {
    //let db = getDatabase();
    let { uid } = this.props;
    let { sport, allGames } = this.state;

    let setId = id;
    //turn off
    if (activeWatch || (!activeWatch && allGames)) setId = id + 0.5;
    //turn off regular
    if (activeWatch && !allGames) setId = null;
    //turn on
    if (allGames && !activeWatch) setId = id;

    //console.log("setId", setId);

    //let setId = activeWatch ? null : id;
    //set(ref(db, "users/" + uid + "/watch/" + sport + "/" + id), setId);
    this.props.toggleGame(sport, id, setId, allGames);
  };

  toggleAllGames = async (checked) => {
    this.setState({ allGames: checked });
    let settingsName = `allGames-${this.props.name}`;
    this.props.togglePrefSettings(settingsName, checked).then((res) => {
      if (!res) {
        this.setState({ allGames: !checked });
      }
    });
  };

  onTeamInput = (e) => {
    let teamInput = e.target.value;
    this.setState({ teamInput });
  };

  onLiveInput = (e) => {
    let liveInput = e.target.value;
    this.setState({ liveInput });
  };

  render() {
    let {
      gamesArr,
      teamsArr,
      showAll,
      hover,
      showMore,
      teamInput,
      liveInput,
      menu,
      allGames,
      showDaysOut,
      upSlider,
    } = this.state;

    let {
      activeTeam,
      activeTeamFav,
      favTeams,
      removeTeam,
      watchGames,
      sport,
      darkmode,
      isMobile,
      preferences,
      isAdmin,
      togglePref,
      activePref,
      name,
    } = this.props;

    let showStockSort = "abc";
    let showSortCaret = "fa-caret-up";

    var options = {
      timeZone: "America/New_York",
      hour12: false,
      hour: "2-digit",
    };
    var d = new Date();
    let hour = d.toLocaleString("en-US", options);
    let day = d.getDay();
    let minute = d.getMinutes();
    hour = Number(hour);

    //return a.name.localeCompare(b.name);

    let upcomingGames = gamesArr.filter((a) => a);

    let today = new Date();
    today = new Date(
      today.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    if (today.getHours() < 5) {
      today.setDate(today.getDate() - 1);
    }

    let oneWeek = new Date(today);
    today.setHours(0, 0, 0, 0);

    /*
    let numPlus = 14;
    if (sport == "basketball") {
      numPlus = 7;
    }
    if (sport == "hockey") {
      numPlus = 7;
    }
    */

    let numPlus = this.state.upSlider + 1;
    if (this.state.upSlider == 0) {
      numPlus = 14;
    }

    oneWeek.setDate(oneWeek.getDate() + numPlus);
    let todayEpoch = today.valueOf() / 1000;
    let oneWeekEpoch = oneWeek.valueOf() / 1000;

    //console.log("upcomingGames", upcomingGames);

    let oneWeekGames = upcomingGames.filter((a) => {
      return (
        (a.startTimestamp <= oneWeekEpoch && a.startTimestamp >= todayEpoch) ||
        a.status?.type == "inprogress"
      );
    });

    oneWeekGames = oneWeekGames.sort((a, b) => {
      return a?.startTimestamp - b?.startTimestamp;
    });

    //  console.log("oneWeekGames", oneWeekGames);

    let timezone = preferences.timezone || "EST";

    let weekSearch = oneWeekGames;
    if (liveInput) {
      weekSearch = oneWeekGames?.filter(
        (a) =>
          a.homeTeam?.short
            ?.toLowerCase()
            ?.includes(liveInput?.toLowerCase()) ||
          a.awayTeam?.short?.toLowerCase()?.includes(liveInput?.toLowerCase())
      );
    }

    let showUpcomingGames = weekSearch.map((a, k) => {
      if (a.status?.type == "canceled") return null;
      let ms = a?.startTimestamp * 1000;

      var options = {
        timeZone: "America/New_York",
        hour12: false,
        hour: "2-digit",
      };

      let gameDate = new Date(ms);
      let today = new Date();

      //timezones

      if (timezone == "PST") {
        gameDate.setHours(gameDate.getHours() - 3);
      }
      if (timezone == "MST") {
        gameDate.setHours(gameDate.getHours() - 2);
      }
      if (timezone == "CST") {
        gameDate.setHours(gameDate.getHours() - 1);
      }

      let dformat1 =
        dayOfWeek(gameDate.getDay()) +
        " " +
        (gameDate.getMonth() + 1) +
        "/" +
        gameDate.getDate();

      let dformat2 = [
        gameDate.toLocaleString("en-US", options),
        gameDate.getMinutes() < 10
          ? "0" + gameDate.getMinutes()
          : gameDate.getMinutes(),
      ].join(":");

      if (
        today.getMonth() == gameDate.getMonth() &&
        today.getDate() == gameDate.getDate()
      ) {
        dformat1 = "Today";
      }

      let dformat = dformat1 + " - " + dformat2 + " " + timezone;

      let score = "";
      if (a.status?.type == "finished") {
        dformat = "FINAL";
        score = a.scoreAwayHomeSlug;
      }
      if (a.status?.type == "inprogress") {
        dformat = "LIVE";
        score = a.scoreAwayHomeSlug;
      }
      let team1Short = this.getShort(a?.homeTeam?.slug, a?.homeTeam?.name);
      let team2Short = this.getShort(a?.awayTeam?.slug, a?.awayTeam?.name);

      if (name == "NCAAF" || name == "NCAAB" || name == "NCAAH") {
        team1Short = a?.homeTeam?.short;
        //team1Short = a?.homeTeam?.name;
        team2Short = a?.awayTeam?.short;
        //team2Short = a?.awayTeam?.name;
      }

      let activeWatch = false;
      if (allGames) {
        //always on, except for 0.5

        activeWatch = watchGames?.filter((b) => b == a.id + 0.5)?.length > 0;
        activeWatch = !activeWatch;
      } else {
        //onle on if id
        activeWatch = watchGames?.filter((b) => b == a.id)?.length > 0;
      }
      //console.log("activeWatch", a.id, activeWatch);

      let isFav = favTeams?.filter(
        (b) => b.team == a.homeTeam.slug || b.team == a.awayTeam.slug
      );
      if (isFav[0]?.onOff || isFav[1]?.onOff) {
        activeWatch = true;
      }
      let isAllStar = a.competition.name == "All Star Game";
      if (
        a.awayTeam?.name?.includes("All-Stars") ||
        a.homeTeam?.name?.includes("All-Stars")
      ) {
        isAllStar = true;
      }

      //moneyline
      let oddsH = a.odds?.moneyLine?.filter(
        (b) => b.name == a.homeTeam.name
      )[0];
      let oddsA = a.odds?.moneyLine?.filter(
        (b) => b.name == a.awayTeam.name
      )[0];

      let oddsHPrice = oddsH?.price;
      if (oddsHPrice > 0) {
        oddsHPrice = `+${oddsHPrice}`;
      }

      let oddsAPrice = oddsA?.price;
      if (oddsAPrice > 0) {
        oddsAPrice = `+${oddsAPrice}`;
      }

      let showH = <div />;
      if (oddsHPrice && isAdmin) {
        //just take off the isAdmin after
        showH = (
          <div
            style={{ opacity: 0.6, marginLeft: 4, fontSize: 14 }}
          >{`(${oddsHPrice})`}</div>
        );
      }

      let showA = <div />;
      if (oddsAPrice && isAdmin) {
        showA = (
          <div
            style={{ opacity: 0.6, marginLeft: 4, fontSize: 14 }}
          >{`(${oddsAPrice})`}</div>
        );
      }

      //spreads
      let spreadH = a.odds?.spread?.filter((b) => b.name == a.homeTeam.name)[0];
      let spreadA = a.odds?.spread?.filter((b) => b.name == a.awayTeam.name)[0];

      let sA = spreadA?.spread;
      let sAP = spreadA?.spreadPrice;
      //+
      if (sA > 0) {
        sA = `+${sA}`;
      }
      if (sAP > 0) {
        sAP = `+${sAP}`;
      }

      let sH = spreadA?.spread;
      let sHP = spreadA?.spreadPrice;

      if (sH > 0) {
        sH = `+${sH}`;
      }
      if (sHP > 0) {
        sHP = `+${sHP}`;
      }

      return (
        <div
          onClick={() => this.toggleGame(a.id, activeWatch)}
          key={k}
          className={darkmode ? "favListItemDark" : "favListItem"}
          style={{ marginLeft: 0 }}
          style={{
            cursor: "pointer",
          }}
        >
          <div className={"favListLeft"}>
            <RssButton
              onClick={() => false && this.toggleGame(a.id, activeWatch)}
              activeRow={true}
              inactive={!activeWatch}
              darkmode={darkmode}
              offOpacity={0.08}
              marginLeft={4}
            />
            <div className={"favListSymbol"} style={{ marginLeft: 2 }}>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {team2Short}
                  {showA}
                </div>
                {isAllStar && "All Star Game"}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {team1Short}
                {showH}
              </div>
            </div>
          </div>

          <div className={"favListRight"}>
            {score == "" ? (
              <>
                {isAdmin && false && spreadA && spreadH && (
                  <div
                    className={"profileTextSub"}
                    style={{
                      display: "flex",
                      height: 40,
                      marginRight: 6,
                      maxWidth: 60,
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        opacity: 1,
                        height: 20,
                        fontSize: 14,
                        textAlign: "right",
                      }}
                    >
                      {`${sA}/${sAP}`}
                    </div>
                    <div
                      style={{ opacity: 1, fontSize: 14, textAlign: "right" }}
                    >{`${sH}/${sHP}`}</div>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    height: 40,
                    width: 0.5,
                    backgroundColor: "#ddd",
                    marginRight: 3,
                  }}
                />
                <div className={"favItemStockPrice"}>
                  <div
                    style={{
                      opacity: 0.6,
                      height: 20,
                      textAlign: "right",
                      fontSize: 14,
                    }}
                  >
                    {dformat1}
                  </div>
                  <div
                    style={{ opacity: 0.6, textAlign: "right", fontSize: 14 }}
                  >
                    {dformat2 + " " + timezone}
                  </div>
                </div>
              </>
            ) : (
              <div className={"favItemStockPrice"}>
                <div style={{ height: 20, textAlign: "right" }}>{score}</div>
                <div style={{ opacity: 0.6, textAlign: "right" }}>
                  {dformat}
                </div>
              </div>
            )}
          </div>
        </div>
      );
    });

    let teamSearch = teamsArr;
    if (teamInput) {
      teamSearch = teamsArr?.filter((a) =>
        a.name?.toLowerCase()?.includes(teamInput?.toLowerCase())
      );
    }

    let showTeams = teamSearch.map((a, k) => {
      let weatherButtonCss = darkmode ? "favListItemDark" : "teamListItem";

      if (
        a.name == "Atlantic Division" ||
        a.name == "Pacific Division" ||
        a.name == "Central Division" ||
        a.name == "Metropolitan Division"
      ) {
        return null;
      }

      let watchTeam = false;
      if (allGames) {
        watchTeam = true;
      }

      let thisTeam = favTeams?.filter((b) => b.team == a.slug);
      //console.log("thisTeam", thisTeam);
      if (thisTeam?.length > 0) {
        let onOff = thisTeam[0]?.onOff;
        //console.log("onOff", onOff);
        watchTeam = onOff;
      }

      return (
        <div key={k} className={"favListBox"}>
          <button
            className={weatherButtonCss}
            onClick={() => this.toggleTeam(watchTeam, a.name, a.slug)}
          >
            <div className={"favListLeft"}>
              <RssButton
                activeRow={true}
                inactive={!watchTeam}
                darkmode={darkmode}
                offOpacity={0.08}
                marginLeft={6}
                marginLeft={4}
              />
              <div className={"teamName"}>{a.name}</div>
              <div className={"favListName"}>{}</div>
            </div>
            <div className={"favListRight"}>
              <div className={"favItemStockPrice"}>{}</div>
            </div>
          </button>
          {activeTeam == k && (
            <button onClick={() => this.props.addTeam(a)}>
              <img src={plus} className={"addButtonTeam"} />
            </button>
          )}
        </div>
      );
    });

    if (
      (gamesArr?.length > 0 || this.state.loaded) &&
      showUpcomingGames?.length < 1
    ) {
      showUpcomingGames = (
        <div
          className={"flexCenter"}
          style={{ alignSelf: "stretch", marginTop: 20, marginBottom: 20 }}
        >
          no games / season over
        </div>
      );
    }

    let activeMenu = darkmode ? "prefMenuItemBlue" : "prefMenuItemDark";
    let inainactiveMenu = darkmode ? "prefMenuItemLight" : "prefMenuItem";

    let daysOutTitle =
      this.state.fast == "0" ? "live only" : `${this.state.fast} days`;

    return (
      <div className={darkmode ? "prefBoxDark" : "prefBox"}>
        {(activePref == "ncaaF" ||
          activePref == "ncaaB" ||
          activePref == "ncaaH") && (
          <div style={{ alignSelf: "stretch" }}>
            <div
              className={"profileText"}
              style={{ marginLeft: 3, marginBottom: 4, opacity: 0.7 }}
            >{`NCAA - beta`}</div>
            <div
              className={"prefMenu"}
              style={{ marginLeft: -2, marginBottom: 4 }}
            >
              <div
                className={activePref == "ncaaF" ? activeMenu : inainactiveMenu}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 17,
                }}
                onClick={() => togglePref("ncaaF")}
              >
                {`Football`}
              </div>
              <div
                className={activePref == "ncaaB" ? activeMenu : inainactiveMenu}
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontSize: 17,
                  opacity: 0.3,
                }}
                onClick={() => togglePref("ncaaB")}
              >
                {`Basketball`}
              </div>
              {false && (
                <div
                  className={
                    activePref == "ncaaH" ? activeMenu : inainactiveMenu
                  }
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontSize: 17,
                  }}
                  onClick={() => togglePref("ncaaH")}
                >
                  {`Hockey`}
                </div>
              )}
            </div>
          </div>
        )}
        {/*second row*/}
        <div
          className={"flexRow"}
          style={{ justifyContent: "space-between", width: 340 }}
        >
          <div
            onClick={() => this.setState({ menu: "games" })}
            className={"graphTextName"}
            style={{
              opacity: 0.8,
              border:
                menu == "games"
                  ? "0px solid #aaa"
                  : darkmode
                  ? "1px solid #555"
                  : "1px solid #aaa",
              backgroundColor:
                menu == "games" ? "#333" : darkmode ? "#222" : "#fbf7f5",
              color: menu == "games" ? "#fff" : darkmode ? "#aaa" : "#333",
              padding: 10,
              alignSelf: "stretch",
              flex: 1,
              paddingBottom: 6,
              marginBottom: -3,
              fontSize: 16,
              height: 42,
              marginRight: 4,
            }}
          >{`upcoming games`}</div>
          {name != "NCAAF" && name != "NCAAB" && name != "NCAAH" && (
            <div
              onClick={() => this.setState({ menu: "customize" })}
              className={"graphTextName"}
              style={{
                opacity: 0.8,
                border:
                  menu == "customize"
                    ? "0px solid #aaa"
                    : darkmode
                    ? "1px solid #555"
                    : "1px solid #aaa",
                backgroundColor:
                  menu == "customize" ? "#333" : darkmode ? "#222" : "#fbf7f5",
                color:
                  menu == "customize" ? "#fff" : darkmode ? "#aaa" : "#333",
                padding: 10,
                width: 100,
                paddingBottom: 6,
                marginBottom: -3,
                fontSize: 16,
                height: 42,
              }}
            >{`favorites`}</div>
          )}
          {false &&
            this.props.sport != "NCAAF" &&
            this.props.sport != "NCAAB" &&
            this.props.sport != "NCAAH" && (
              <div
                onClick={() => this.setState({ showDaysOut: !showDaysOut })}
                className={"graphTextName"}
                style={{
                  opacity: 0.8,
                  border: showDaysOut
                    ? "0px solid #aaa"
                    : darkmode
                    ? "1px solid #555"
                    : "1px solid #aaa",
                  backgroundColor: showDaysOut
                    ? "#777"
                    : darkmode
                    ? "#222"
                    : "#fbf7f5",
                  color: showDaysOut ? "#fff" : darkmode ? "#aaa" : "#333",
                  padding: 10,
                  width: 80,
                  paddingBottom: 6,
                  marginBottom: -3,
                  fontSize: 16,
                  height: 42,
                }}
              >
                {daysOutTitle}
              </div>
            )}
        </div>
        {(name == "NCAAF" || name == "NCAAB" || name == "NCAAH") &&
          menu == "games" && (
            <div
              style={{
                alignSelf: "stretch",
                marginTop: 10,
                marginLeft: 4,
              }}
            >
              <input
                type="text"
                className={
                  darkmode ? "searchTeamSubmitBoxDark" : "searchTeamSubmitBox"
                }
                value={liveInput}
                onChange={this.onLiveInput}
                placeholder="search"
              />
            </div>
          )}

        <div className={"favList"} style={{ marginBottom: 0 }}>
          {isAdmin && showDaysOut && (
            <UpcomingSlider
              darkmode={darkmode}
              setValue={this.setSlider}
              value={this.state.upSlider}
              fast={this.state.fast}
              setFast={(fast) => this.setState({ fast })}
            />
          )}
          {menu == "games" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {showUpcomingGames}
            </div>
          )}

          {menu == "customize" && (
            <div>
              {this.props.sport != "NCAAF" &&
                this.props.sport != "NCAAB" &&
                this.props.sport != "NCAAH" && (
                  <>
                    <div
                      className={"flexRow"}
                      style={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 10,
                        alignSelf: "stretch",
                        border: darkmode ? "1px solid #555" : "1px solid #aaa",
                        marginTop: 4,
                        height: 46,
                      }}
                    >
                      <div>all teams</div>
                      <div className={"flexRow"}>
                        <div
                          className={"profileTextSub"}
                          style={{ opacity: 1, marginRight: 5 }}
                        ></div>
                        <Switch
                          style={{ height: 18, width: 28 }}
                          styles={{
                            track: {
                              backgroundColor: darkmode ? "#555" : "#bbb",
                            },
                            trackChecked: {
                              backgroundColor: darkmode ? "#728cd4" : "#99ace0",
                            },
                          }}
                          onChange={this.toggleAllGames}
                          value={this.state.allGames ? 1 : 0}
                        />
                        <div
                          className={"profileTextSub"}
                          style={{ opacity: 1, marginLeft: 5 }}
                        ></div>
                      </div>
                    </div>
                    {false && (
                      <div
                        className={"flexRow"}
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: 10,
                          alignSelf: "stretch",
                          border: darkmode
                            ? "1px solid #555"
                            : "1px solid #aaa",
                          marginTop: 10,
                          height: 46,
                        }}
                      >
                        <div>stream upcoming dates</div>
                        <div className={"flexRow"}>
                          <div
                            className={"profileTextSub"}
                            style={{ opacity: 1, marginRight: 5 }}
                          ></div>
                          <Switch
                            style={{ height: 18, width: 28 }}
                            styles={{
                              track: {
                                backgroundColor: darkmode ? "#555" : "#bbb",
                              },
                              trackChecked: {
                                backgroundColor: darkmode
                                  ? "#728cd4"
                                  : "#99ace0",
                              },
                            }}
                            onChange={this.toggleUp}
                            value={this.state.up ? 1 : 0}
                          />
                          <div
                            className={"profileTextSub"}
                            style={{ opacity: 1, marginLeft: 5 }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {false && this.state.up && (
                      <UpcomingSlider
                        darkmode={darkmode}
                        setValue={this.setSlider}
                        value={this.state.upSlider}
                      />
                    )}
                  </>
                )}
              {this.props.sport != "NCAAF" &&
                this.props.sport != "NCAAB" &&
                this.props.sport != "NCAAH" &&
                false && (
                  <div
                    className={"flexCol"}
                    style={{ alignSelf: "stretch", alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: 20,
                        border: darkmode
                          ? "0px solid #555"
                          : "0px solid #e0e0e0",
                        borderBottomWidth: 1,
                        marginTop: 10,
                      }}
                    />
                  </div>
                )}
              <div
                className={"flexCol"}
                style={{
                  padding: 7,
                  paddingLeft: 10,
                  paddingRight: 10,
                  alignSelf: "stretch",
                  border: darkmode ? "1px solid #555" : "1px solid #aaa",
                  marginTop: 10,
                }}
              >
                <div
                  className={"flexRow"}
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>search:</div>
                  <input
                    type="text"
                    className={
                      darkmode
                        ? "searchTeamSubmitBoxDark"
                        : "searchTeamSubmitBox"
                    }
                    value={teamInput}
                    onChange={this.onTeamInput}
                    placeholder="search"
                  />
                </div>
              </div>
              <div style={{ alignSelf: "stretch", marginTop: 6 }}>
                {showTeams}
              </div>
            </div>
          )}
          {showMore && (
            <div
              className={"graphTextName"}
              style={{
                alignSelf: "flex-end",
                opacity: 0.8,
                backgroundColor: "#333",
                color: "#fff",
                padding: 10,
                width: 140,
                paddingBottom: 6,
                marginBottom: -3,
                fontSize: 16,
                height: 42,
                marginBottom: 5,
              }}
            >{`show more`}</div>
          )}
        </div>
      </div>
    );
  }
}

export default ListSport;

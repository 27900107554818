import React, { useEffect, useState } from "react";
import Axios from "axios";

const RecentPings = ({ uid }) => {
  let [pings, setPings] = useState([]);

  useEffect(() => {
    console.log("RecentPings");
    Axios.get("http://dev.quotron.co/admin/pings/" + uid).then((res) => {
      console.log("RecentPings", res.data);
      setPings(res.data);
    });
  }, []);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return (
        "Today " +
        date.toLocaleString("en-US", {
          timeZone: "America/Denver",
          timeStyle: "short",
        })
      );
    } else if (date.toDateString() === yesterday.toDateString()) {
      return (
        "Yesterday " +
        date.toLocaleString("en-US", {
          timeZone: "America/Denver",
          timeStyle: "short",
        })
      );
    } else {
      return date.toLocaleString("en-US", {
        timeZone: "America/Denver",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  };

  return (
    <div className={"flexCol graphTextName"} style={{ marginBottom: 2 }}>
      <div style={{ marginBottom: 10 }}>Pings</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          maxHeight: 200,
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 20,
            marginBottom: 5,
          }}
        >
          <div style={{ minWidth: 180 }}>newest</div>
          <div style={{ padding: "0 4px" }}>prod</div>
          <div style={{ padding: "0 4px" }}>backup</div>
        </div>
        {[...pings]
          .sort((a, b) => b.timestamp - a.timestamp)
          .map((ping) => (
            <div key={ping._id} style={{ display: "flex", gap: 20 }}>
              <div style={{ minWidth: 180 }}>
                {formatTimestamp(ping.timestamp)}
              </div>
              <div
                style={{
                  padding: "0 10px",
                  backgroundColor: ping.prod === false ? "#ffcccc" : "#ccffcc",
                }}
              >
                {ping.prod} <span style={{ opacity: 0.4 }}>ms</span>
              </div>
              <div
                style={{
                  padding: "0 10px",
                  backgroundColor:
                    ping.backup === false ? "#ffcccc" : "#ccffcc",
                }}
              >
                {ping.backup} <span style={{ opacity: 0.4 }}>ms</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentPings;

import React, { Component } from "react";
import Axios from "axios";
import {
  getDatabase,
  ref,
  onValue,
  child,
  get,
  set,
  update,
} from "firebase/database";
import { initializeApp, getApps, getApp } from "firebase/app";
import CsvDownload from "react-json-to-csv";

/*
import { w3cwebsocket as W3CWebSocket } from "websocket";
const client = new W3CWebSocket("ws://localhost:7071");
*/

//stanDashboard firebase
const firebaseConfig = {
  apiKey: "AIzaSyC9f9HBhku_Gn0Hd-YZxp3WogXjrMVCdcg",
  authDomain: "auth.quotron.co",
  projectId: "standashboard-99094",
  storageBucket: "standashboard-99094.appspot.com",
  messagingSenderId: "871432953677",
  appId: "1:871432953677:web:f30caf1a1a21e59b6c6104",
  measurementId: "G-NLVNM53S35",
};

import LittleSocket from "./admin/LittleSocket";
import BoxChart from "./admin/BoxChart";
import Ping from "./admin/Ping";
import Uptime from "./admin/Uptime";
import Test from "./admin/Test";
import Groups from "./admin/Groups";
import Ota from "./admin/Ota";
import UpdateBox from "./admin/UpdateBox";
import ShopBox from "./admin/ShopBox";
import Data from "./admin/Data";
class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boxRes: {
        data: "",
      },
      registeredUsers: 0,
      stocksTotal: 0,
      todayDeviceArr: 0,
      stats: null,
      users: null,
      portfolioNum: 0,
      textString: "@#W",

      activeTab: "stats",
      userDevices: [],
      bothArr: [],
      logs: [],
      confirm: false,
    };

    let url = window.location.hostname;
    if (url.includes("localhost")) {
      this.state.env = "local";
    }
    if (url.includes("dev.quotron.co")) {
      this.state.env = "dev";
    }

    let apps = getApps().length;
    if (!apps) {
      const app = initializeApp(firebaseConfig);
    } else {
      //const app = getApp();
    }

    //date formatted
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    var year = yesterday.getFullYear();
    var month = (1 + yesterday.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var dayy = yesterday.getDate().toString();
    let dateFormatted = month + "-" + dayy + "-" + year;

    this.state.dateFormatted = dateFormatted;
  }

  componentDidMount() {
    this.getUsers();
    this.getStocks();
    this.getDeviceHit();
    this.getStats();
    this.getDevices();
    this.getLogs();
  }

  getLogs = () => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/log/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        let logs = res.data || [];
        this.setState({ logs });
      }
    });
  };

  getGroups = () => {
    //add group
    /*
    let { uid } = this.props;
    var options = {
      method: "POST",
      url: "https://quotron.co/modifyGroup/",
      data: {
        uid,
        target: "111111",
        group: "sleep",
        value: true,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        console.log("modifyGroup", res.data);
      }
    });
    */
  };

  getDevices = () => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/adminDevices/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        //let userDevices = Object.values(res?.data) || [];
        let userDevices = res?.data || [];

        this.setState({ userDevices });
      }
    });
  };

  getStats = () => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/adminStats/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        if (res) {
          //this.stockToDB(res.data);
          let stats = res.data; //Object.values(res.data);
          this.setState({ stats });
          //let usersTotal = users.length;
          //this.setState({ registeredUsers: usersTotal });
        } else {
          //this.setState({ networkErr: "Bad Request" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        //this.setState({ networkErr: "Bad Request" });
      });
  };

  getStocks = () => {
    let stocksTotal = this.props.mongoStocks?.length;
    this.setState({ stocksTotal });
  };

  getDeviceHit = () => {
    let db = getDatabase();
    get(ref(db, "deviceHit"))
      .then((snap) => {
        let rugVal = snap.val();

        //let rugs = Object.values(rugVal);

        //date formatted
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        var year = yesterday.getFullYear();
        var month = (1 + yesterday.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;
        var dayy = yesterday.getDate().toString();
        dayy = ("0" + dayy).slice(-2);
        let dateFormatted = month + "-" + dayy + "-" + year;

        let todayRug = rugVal[dateFormatted];

        let todayDeviceArr = Object.values(todayRug);
        this.setState({ todayDeviceArr: todayDeviceArr.length });
        //dateFormatted;

        //let rugsTotal = rugs.length;
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  getEmails = async (userId) => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/emails/" + uid,

      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      Axios.request(options).then((res) => {
        if (res) {
          resolve(res.data);
        } else {
          reject("");
        }
      });
    });
  };

  getEmailsOfId = async (userId) => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/email/" + uid + "/" + userId,
      params: {
        uid,
        userId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      Axios.request(options).then((res) => {
        if (res) {
          resolve(res.data);
        } else {
          reject("");
        }
      });
    });
  };

  getUserEmails = async (users) => {
    let idArr = users?.map((a) => a.uid);
    let bothArr = [];
    let arr = await this.getEmails();
    await Promise.all(
      idArr.map(async (a) => {
        let singleID = {};
        let email = arr?.mongo?.filter((b) => b.uid == a)[0]?.email;
        if (!email) {
          email = await this.getEmailsOfId(a);
        }
        singleID = {
          id: a,
          email,
        };
        bothArr.push(singleID);
      })
    );
    this.setState({ bothArr });
  };

  getUsers = () => {
    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/users/" + uid,
      params: {
        uid,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.request(options)
      .then((res) => {
        if (res) {
          let users = res.data;
          this.getUserEmails(users);
          let porfolioUsers = users.filter(
            (a) => Object.values(a.portfolio || {}).length > 0
          );
          let usersTotal = users.length;
          this.setState({
            registeredUsers: usersTotal,
            users: res.data,
            portfolioNum: porfolioUsers.length,
          });
        } else {
          //this.setState({ networkErr: "Bad Request" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        //this.setState({ networkErr: "Bad Request" });
      });
  };

  onTextChange = (event) => {
    let textString = event.target.value;
    this.setState({ textString });
  };

  onSearchPanels = (event) => {
    let searchPanels = event.target.value;
    this.setState({ searchPanels });
  };

  onSearchDevice = (event) => {
    let searchDevice = event.target.value;
    this.setState({ searchDevice });
  };

  onNewDevice = (event) => {
    let newDevice = event.target.value;
    this.setState({ newDevice });
  };

  onSearchVersion = (event) => {
    let searchVersion = event.target.value;
    this.setState({ searchVersion });
  };

  onSearchEmail = (event) => {
    let searchEmail = event.target.value;
    this.setState({ searchEmail });
  };

  onTextSubmit = (event) => {
    event.preventDefault();
    let { uid } = this.props;
    let { textString } = this.state;
    let db = getDatabase();
    set(ref(db, "users/" + uid + "/testString"), {
      string: textString,
      times: 3,
    });
    this.setState({ textString: "@#W" });
  };

  clickDeviceRow = (a) => {
    let { activeDeviceRow } = this.state;
    let nextDeviceRow = a.id;
    if (activeDeviceRow == a.id) {
      nextDeviceRow = null;
    }
    this.setState({ activeDeviceRow: nextDeviceRow, confirm: false });
  };

  writeLog = (string) => {
    let { uid } = this.props;
    var options = {
      method: "POST",
      url: "https://quotron.co/admin/log/",
      data: {
        uid,
        string,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        console.log("admin log res", res);
        this.getLogs();
      }
    });
  };

  removeAccountFromDevice = (obj) => {
    let dId = obj?.id;
    let { uid } = this.props;
    let fromId = obj?.account?.uid;
    var options = {
      method: "GET",
      url:
        "https://quotron.co/admin/removeAD/" + uid + "/" + dId + "/" + fromId,
      params: {
        uid,
        dId,
        fromId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        this.getDevices();
        this.writeLog(`remove Account from dId: ${dId}`);
      }
    });
  };

  addNewDevice = (e) => {
    e.preventDefault();
    let { newDevice } = this.state;
    let formattedDevice = newDevice.replaceAll("-", "").replaceAll(" ", "");
    newDevice =
      formattedDevice.slice(0, 4) +
      "-" +
      formattedDevice.slice(4, 7) +
      "-" +
      formattedDevice.slice(7, 10) +
      "-" +
      formattedDevice.slice(10, 13) +
      "-" +
      formattedDevice.slice(13);

    let { uid } = this.props;
    var options = {
      method: "GET",
      url: "https://quotron.co/admin/newDevice/" + uid + "/" + newDevice,
      params: {
        uid,
        newDevice,
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        this.writeLog(`new device added : ${newDevice}`);
        this.getDevices();
        this.setState({ newDevice: "" });
      }
    });
  };

  downloadEmails = () => {
    let { bothArr } = this.state;
    let emailString = "";
    bothArr?.map((a) => {
      if (a.email) {
        emailString += `${a.email},`;
      }
    });
    emailString = emailString.slice(0, -1);
    const date = new Date();
    let fileName = `qtronEmails_${date.getTime()}.txt`;

    const blob = new Blob([emailString], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  };

  render() {
    let {
      registeredUsers,
      usersFuture,
      stocksTotal,
      todayDeviceArr,
      stats,
      dateFormatted,
      users,
      portfolioNum,
      deviceOTA,
      urlOTA,
      activeTab,
      userDevices,
      searchDevice,
      activeDeviceRow,
      bothArr,
      searchEmail,
      searchPanels,
      searchVersion,
      logs,
      newDevice,
      boxHits,
    } = this.state;
    let { isMobile, uid, darkmode, screenWidth } = this.props;

    //console.log("userDevices", userDevices);

    let statsData = [];
    for (const date in stats) {
      let insideObj = stats[date];
      statsData.push({
        date: date,
        users: insideObj.users,
        stocks: insideObj.stocks,
        devices: insideObj.devLength,
      });
    }

    let usersData = [];
    for (const uidKey in users) {
      let insideObj = users[uidKey];
      usersData.push({
        uid: uidKey,
        city: insideObj.location?.city,
        country: insideObj.location?.country,
        postal: insideObj.location?.post_code,
      });
    }

    let emailsCsv = [];
    for (const uidKey in userDevices) {
      let insideObj = userDevices[uidKey];
      //console.log("insideObj", insideObj);
      let userIdOfDevice = insideObj?.account?.uid;
      let idRow = insideObj?.id;
      let emailOf =
        bothArr?.filter((b) => b.id == userIdOfDevice)[0]?.email || null;
      if (emailOf) {
        emailsCsv.push({
          email: emailOf,
          deviceId: idRow,
          uid: userIdOfDevice,
        });
      }
    }

    let portPerc = ((portfolioNum / registeredUsers) * 100).toFixed(2);

    let activeMenu = darkmode ? "prefMenuItemBlue" : "prefMenuItemDark";
    let inainactiveMenu = darkmode ? "prefMenuItemLight" : "prefMenuItem";

    userDevices = userDevices.map((a) => {
      if (!users) return;
      let userDevice = users?.filter((b) => b.device?.[a.id])[0];

      let version = userDevice?.device[a.id]?.version;
      let panels = userDevice?.device[a.id]?.panels;
      return {
        ...a,
        email: bothArr.filter((b) => b?.id == a.account?.uid)[0]?.email,
        version,
        panels,
      };
    });

    if (searchDevice?.length > 0) {
      userDevices = userDevices?.filter((a) => a.id?.includes(searchDevice));
    }

    if (searchPanels?.length > 0) {
      userDevices = userDevices?.filter((a) =>
        a.panels?.includes(searchPanels)
      );
    }

    if (searchVersion?.length > 0) {
      userDevices = userDevices?.filter((a) =>
        a.version?.includes(searchVersion)
      );
    }

    if (searchEmail?.length > 0) {
      userDevices = userDevices?.filter((a) => a.email?.includes(searchEmail));
    }

    let showDevices = userDevices?.map((a, k) => {
      if (!a) return null;

      return (
        <div
          key={k}
          className={"flexRow"}
          onClick={() => this.clickDeviceRow(a)}
          style={{
            backgroundColor: activeDeviceRow == a.id ? "#555" : "#fbf7f5",
            color: activeDeviceRow == a.id ? "#FFF" : "#000",
          }}
        >
          <div
            className={"flexRow"}
            style={{
              minWidth: 173,
              height: 33,
              border: "1px solid #ccc",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {a.id}
          </div>
          <div
            className={"flexRow"}
            style={{
              minWidth: 40,
              height: 33,
              border: "1px solid #ccc",
              borderLeftWidth: 0,
              alignItems: "center",
            }}
          >
            {a.panels || ""}
          </div>

          <div
            className={"flexRow"}
            style={{
              minWidth: 60,
              height: 33,
              border: "1px solid #ccc",
              borderLeftWidth: 0,
              alignItems: "center",
            }}
          >
            {a.version || ""}
          </div>
          <div
            className={"flexRow"}
            style={{
              minWidth: 50,
              height: 33,
              border: "1px solid #ccc",
              borderLeftWidth: 0,
              alignItems: "center",
            }}
          >
            {a?.sku?.type || ""}
          </div>
          <div
            className={"flexRow"}
            style={{
              minWidth: 230,
              height: 33,
              border: "1px solid #ccc",
              borderLeftWidth: 0,
              alignItems: "center",
            }}
          >
            {a.email || ""}
          </div>
          <div
            className={"flexRow"}
            style={{
              minWidth: 300,
              height: 33,
              border: "1px solid #ccc",
              borderLeftWidth: 0,
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            {a.account?.uid || ""}
          </div>
        </div>
      );
    });

    let updateObj =
      userDevices.filter((a) => a?.id == activeDeviceRow)[0] || null;
    console.log("updateObj", updateObj);

    let logsDate = logs.sort((a, b) => b.timestamp - a.timestamp);
    let showLogs = logsDate?.map((a, k) => {
      let showDate = new Date(a.timestamp).toLocaleString();
      return (
        <div
          className={"flexRow"}
          key={k}
          style={{ border: "0px solid #ccc", borderBottomWidth: 1 }}
        >
          <div
            style={{
              minWidth: 160,
              fontSize: 15,
              border: "0px solid #ccc",
              borderRightWidth: 1,
            }}
          >
            {showDate}
          </div>
          <div style={{ minWidth: 100, fontSize: 15 }}>{a.string}</div>
        </div>
      );
    });

    let small = {
      maxWidth: screenWidth + 5,
      marginLeft: -12,
      marginRight: -12,
      paddingLeft: 12,
    };
    let big = {
      width: screenWidth,
      alignSelf: "stretch",
      justifyContent: "flex-start",
      paddingLeft: screenWidth / 2 - 180,
    };

    let dynamic = isMobile ? small : big;

    return (
      <div className={""} style={{ ...dynamic }}>
        <div className={"prefMenu"} style={{ alignSelf: "flex-start" }}>
          <div
            className={activeTab == "stats" ? activeMenu : inainactiveMenu}
            onClick={() => this.setState({ activeTab: "stats" })}
          >
            stats
          </div>
          <div
            className={activeTab == "devices" ? activeMenu : inainactiveMenu}
            onClick={() => this.setState({ activeTab: "devices" })}
          >
            devices
          </div>
          {false && (
            <div
              className={activeTab == "uptime" ? activeMenu : inainactiveMenu}
              onClick={() => this.setState({ activeTab: "uptime" })}
            >
              uptime
            </div>
          )}
          <div
            className={activeTab == "test" ? activeMenu : inainactiveMenu}
            onClick={() => this.setState({ activeTab: "test" })}
          >
            test
          </div>
          <div
            className={activeTab == "ota" ? activeMenu : inainactiveMenu}
            onClick={() => this.setState({ activeTab: "ota" })}
          >
            update
          </div>
          {(this.state.env == "local" || this.state.env == "dev") && (
            <div
              className={activeTab == "data" ? activeMenu : inainactiveMenu}
              onClick={() => this.setState({ activeTab: "data" })}
            >
              data
            </div>
          )}
        </div>
        {activeTab == "devices" && (
          <div
            className={"prefMenu flexCol"}
            style={{ color: darkmode ? "#ccc" : "#555" }}
          >
            <div className={"settingsTitle"} style={{ marginTop: 10 }}>
              all devices:
            </div>
            <div
              style={{
                border: "1px solid #777",
                paddingLeft: 6,
              }}
            >
              <div
                className={"flexRow"}
                style={{
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div className={"flexCol"}>
                  <div className={"settingsTitle"}>device id:</div>
                  <input
                    type="text"
                    className={"portfolioShares"}
                    style={{ minWidth: 240, marginBottom: -4 }}
                    value={searchDevice}
                    onChange={this.onSearchDevice}
                    placeholder={"0000-000-000-000-000"}
                  />
                </div>
                <div className={"flexCol"}>
                  <div className={"settingsTitle"}>panels:</div>
                  <input
                    type="text"
                    className={"portfolioShares"}
                    style={{ minWidth: 80, marginBottom: -4 }}
                    value={searchPanels}
                    onChange={this.onSearchPanels}
                    placeholder={"1x3"}
                  />
                </div>
              </div>
              <div
                className={"flexRow"}
                style={{
                  alignSelf: "stretch",
                  justifyContent: "space-between",
                }}
              >
                <div className={"flexCol"}>
                  <div className={"settingsTitle"}>email:</div>
                  <input
                    type="text"
                    className={"portfolioShares"}
                    style={{ minWidth: 240, marginBottom: 10 }}
                    value={searchEmail}
                    onChange={this.onSearchEmail}
                    placeholder={"name@email.com"}
                  />
                </div>
                <div className={"flexCol"}>
                  <div className={"settingsTitle"}>version:</div>
                  <input
                    type="text"
                    className={"portfolioShares"}
                    style={{ minWidth: 80, marginBottom: 10 }}
                    value={searchVersion}
                    onChange={this.onSearchVersion}
                    placeholder={"2.8.0"}
                  />
                </div>
              </div>

              <div
                className={"flexCol"}
                style={{
                  height: isMobile ? 200 : 400,
                  marginLeft: -6,
                  width: isMobile ? 350 : 700,
                  overflowX: "scroll",
                  overflow: "scroll",
                  border: "0px solid #555",
                  borderTopWidth: 1,
                }}
              >
                {showDevices}
              </div>
            </div>
            {activeDeviceRow && (
              <>
                <div className={"settingsTitle"} style={{ marginBottom: 1 }}>
                  update:
                </div>
                <div
                  style={{
                    border: "1px solid #777",
                    paddingLeft: 6,
                    paddingBottom: 6,
                  }}
                >
                  {updateObj && (
                    <div>
                      <div className={"settingsTitle"}>id: {updateObj?.id}</div>
                      <div className={"settingsTitle"}>{updateObj?.email}</div>
                      <div className={"settingsTitle"}>
                        user: {updateObj?.account?.uid}
                      </div>

                      <div className={"flexRow"} style={{ marginTop: 12 }}>
                        <div
                          onClick={() => this.setState({ confirm: true })}
                          style={{
                            border: "1px solid #ccc",
                            alignSelf: "stretch",
                            padding: 6,
                            backgroundColor: "#999",
                            color: "#fff",
                            width: 250,
                            cursor: "pointer",
                          }}
                        >
                          remove account from device
                        </div>
                        {this.state.confirm && (
                          <div
                            onClick={() => {
                              this.removeAccountFromDevice(updateObj);
                              this.setState({ confirm: false });
                            }}
                            style={{
                              border: "1px solid #ccc",
                              marginLeft: 10,
                              padding: 6,
                            }}
                          >
                            confirm
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <ShopBox
              activeDeviceRow={activeDeviceRow}
              uid={uid}
              isMobile={isMobile}
              userDevices={userDevices}
              updateObj={updateObj}
              writeLog={this.writeLog}
            />
            <UpdateBox
              activeDeviceRow={activeDeviceRow}
              uid={uid}
              isMobile={isMobile}
              userDevices={userDevices}
            />
            <BoxChart
              activeDeviceRow={activeDeviceRow}
              uid={uid}
              isMobile={isMobile}
            />
            <Ping
              activeDeviceRow={activeDeviceRow}
              userDevices={userDevices}
              uid={uid}
              isMobile={isMobile}
            />

            <div className={"settingsTitle"} style={{ marginTop: 20 }}>
              add new device:
            </div>
            <div
              style={{
                border: "1px solid #777",
                paddingLeft: 6,
                paddingBottom: 20,
              }}
            >
              <div className={"settingsTitle"}>device id:</div>
              <form
                onSubmit={(e) => this.addNewDevice(e)}
                className={"flexRow"}
              >
                <input
                  type="text"
                  className={"portfolioShares"}
                  style={{ minWidth: 240, marginBottom: -4 }}
                  value={newDevice}
                  onChange={this.onNewDevice}
                  placeholder={"0000-000-000-000-000"}
                />
                <div
                  className={"searchSubmitButtonBox"}
                  style={{ marginLeft: 6 }}
                >
                  <input
                    type="submit"
                    className={"searchSubmitButton"}
                    value=""
                  />
                  <div className={"submitPlusFont"}>+</div>
                </div>
              </form>
            </div>
            <div className={"settingsTitle"}>log:</div>
            <div
              style={{
                border: "1px solid #777",
                paddingBottom: 4,
                height: 100,
                width: isMobile ? 350 : 700,
                overflowX: "scroll",
                overflow: "scroll",
                marginBottom: 10,
              }}
            >
              {showLogs}
            </div>

            <div className={"settingsTitle"}>groups:</div>
            <div
              style={{
                border: "1px solid #777",
                paddingBottom: 4,
                width: isMobile ? 350 : 700,
                overflowX: "scroll",
                overflow: "scroll",
                marginBottom: 10,
              }}
            >
              <Groups uid={this.props.uid} userDevices={userDevices} />
            </div>
          </div>
        )}
        {activeTab == "stats" && (
          <div
            className={"prefMenu flexCol"}
            style={{ color: darkmode ? "#ccc" : "#555" }}
          >
            <div className={"settingsTitle"}>Admin:</div>
            <div className={"deviceidtext"}>
              registered users: {registeredUsers}
            </div>
            <div className={"deviceidtext"}>
              portfolio users : {`${portfolioNum} | ${portPerc}%`}
            </div>
            <div className={"deviceidtext"}>
              # of stocks tracked: {stocksTotal}
            </div>
            <div className={"deviceidtext"}>
              devices active/day : {todayDeviceArr}
            </div>
            {stats && (
              <div
                style={{
                  border: "1px solid #ccc",
                  alignSelf: "stretch",
                  padding: 4,
                  backgroundColor: "#999",
                  marginTop: 12,
                  width: 130,
                  cursor: "pointer",
                }}
              >
                <CsvDownload
                  filename={`${dateFormatted}-quotronStats.csv`}
                  data={statsData}
                >
                  <div
                    className={"profileTextSub"}
                    style={{ fontSize: 16, color: "#fff" }}
                  >
                    download stats
                  </div>
                </CsvDownload>
              </div>
            )}
            {users && (
              <div
                style={{
                  border: "1px solid #ccc",
                  alignSelf: "stretch",
                  padding: 4,
                  backgroundColor: "#999",
                  marginTop: 12,
                  width: 130,
                  cursor: "pointer",
                }}
              >
                <CsvDownload
                  filename={`${dateFormatted}-quotronUsers.csv`}
                  data={usersData}
                >
                  <div
                    className={"profileTextSub"}
                    style={{ fontSize: 16, color: "#fff" }}
                  >
                    download users
                  </div>
                </CsvDownload>
              </div>
            )}
            {bothArr?.length > 0 && (
              <CsvDownload
                filename={`${dateFormatted}-emails.csv`}
                data={emailsCsv}
                style={{
                  border: "1px solid #ccc",
                  alignSelf: "stretch",
                  padding: 4,
                  backgroundColor: "#999",
                  marginTop: 12,
                  width: 130,
                  cursor: "pointer",
                }}
              >
                <div
                  className={"profileTextSub"}
                  style={{ fontSize: 16, color: "#fff" }}
                >
                  download emails
                </div>
              </CsvDownload>
            )}

            <div className={"settingsDivider"} style={{ marginTop: 20 }} />

            <LittleSocket />
          </div>
        )}
        {activeTab == "uptime" && <Uptime isMobile={isMobile} />}
        {activeTab == "test" && (
          <Test
            isMobile={isMobile}
            uid={uid}
            bothArr={bothArr}
            userDevices={userDevices}
          />
        )}
        {activeTab == "ota" && (
          <Ota
            isMobile={isMobile}
            uid={uid}
            bothArr={bothArr}
            userDevices={userDevices}
            getUsers={this.getUsers}
          />
        )}
        {activeTab == "data" && (
          <Data
            isMobile={isMobile}
            uid={uid}
            bothArr={bothArr}
            userDevices={userDevices}
            getUsers={this.getUsers}
          />
        )}
      </div>
    );
  }
}

export default Admin;

import React, { useState, useEffect } from "react";
import Axios from "axios";

const ShopBox = ({ uid, activeDeviceRow, updateObj, writeLog }) => {
  if (!activeDeviceRow) return null;
  let [shop, setShop] = useState(null);
  console.log("shop", shop);

  useEffect(() => {
    console.log("activeDeviceRow", activeDeviceRow, "updateObj", updateObj);
    getShop(updateObj?.account?.uid);
  }, [activeDeviceRow]);

  console.log("updateObj", updateObj);

  let getShop = async (sid) => {
    console.log("admin/getShop", sid, uid);
    let res = await Axios.post("https://quotron.co/admin/getShop", {
      sid,
      uid,
    });
    console.log("getShop", res.data);
    setShop(res.data.currentShop);
  };

  let toggleShop = async (name, value) => {
    console.log("toggleShop", name, value);
    let type = "finance";
    if (
      name == "nfl" ||
      name == "nhl" ||
      name == "ncaa" ||
      name == "nfl" ||
      name == "nba" ||
      name == "mlb" ||
      name == "nippon" ||
      name == "pga"
    ) {
      type = "sports";
    }
    let res = await Axios.post("https://quotron.co/admin/toggleShop", {
      uid: uid,
      sid: updateObj?.account?.uid,
      name,
      to: !value,
      type,
    });
    console.log("toggleShop", res.data);
    getShop(updateObj?.account?.uid);
  };

  let shopList = shop
    ? Object.entries(shop).map(([name, value]) => {
        return (
          <div
            key={name}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: "1px solid #ccc",
            }}
          >
            <div
              style={{
                paddingLeft: 4,
                width: 100,
                borderRight: "1px solid #ccc",
              }}
            >
              {name}
            </div>
            <div
              style={{
                paddingLeft: 4,
                width: 100,
                backgroundColor: value === true ? "#90eebf" : "transparent",
                height: 30,
                display: "flex",
                alignItems: "center",
              }}
            >
              {value.toString()}
            </div>
            <div
              style={local.button}
              onClick={() => {
                toggleShop(name, value);
                writeLog(`${updateObj?.email} - ${name} - ${!value}`);
              }}
            >
              toggle
            </div>
          </div>
        );
      })
    : null;

  return (
    <>
      <div className={"settingsTitle"} style={{ marginTop: 20 }}>
        shop:
      </div>
      <div
        style={{
          border: "1px solid #777",
          paddingLeft: 6,
          paddingBottom: 20,
        }}
      >
        <div className={"settingsTitle"} style={{ marginBottom: 10 }}>
          {`user: ${updateObj?.email} - ${updateObj?.account?.uid}`}
        </div>
        {shopList}
      </div>
    </>
  );
};

const local = {
  button: {
    border: "1px solid #ccc",
    width: 100,
    padding: 6,
    backgroundColor: "#999",
    color: "#fff",
    cursor: "pointer",
  },
  buttonNo: {
    border: "1px solid #ccc",
    width: 100,
    padding: 6,
    backgroundColor: "#FFF",
    color: "#000",
    cursor: "pointer",
  },
  addedText: {
    marginLeft: 10,
    color: "#888",
  },
};

export default ShopBox;

import React, { useState, useEffect } from "react";
import Axios from "axios";

let Groups = ({ uid, userDevices }) => {
  let [groupObj, setGroupObj] = useState({});
  let [type, setType] = useState(null);
  let [text, setText] = useState("");
  let [filter, setFilter] = useState("");

  useEffect(() => {
    getGroups();
  }, []);

  let getGroups = async () => {
    var options = {
      method: "GET",
      url: `https://quotron.co/admin/groups/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.request(options).then((res) => {
      if (res) {
        //let data = res.data.groups?.filter((a) => a.type == "groupData")[0];
        console.log("res", res.data);
        setGroupObj(res.data);
      }
    });
  };

  let addToGroup = async (text) => {
    await Axios.post("https://quotron.co/admin/groupAdd", {
      uid,
      did: text,
      group: type,
    });
    getGroups();
    setText("");
  };

  console.log("groupObj", groupObj);

  let showAdded = groupObj?.groups?.map((group, k) => {
    if (group.group === type) {
      return Object.keys(group.devices || {}).map((deviceId, i) => {
        if (!filter || deviceId.includes(filter)) {
          let email = userDevices?.find((a) => a.id == deviceId)?.email;
          return (
            <div key={i} style={local.idRow}>
              <div style={{ width: 200 }}>{deviceId}</div>
              <div style={{ marginLeft: 20, width: 140 }}>{email}</div>
            </div>
          );
        }
        return null;
      });
    }
    return null;
  });

  return (
    <div
      style={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          flexDirection: "row",
          marginBottom: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          onClick={() => setType("upcoming")}
          style={type == "upcoming" ? local.typeRowActive : local.typeRow}
        >{`upcoming`}</div>
        <div
          onClick={() => setType("sleep")}
          style={type == "sleep" ? local.typeRowActive : local.typeRow}
        >{`sleep`}</div>
        <div
          onClick={() => setType("store")}
          style={type == "store" ? local.typeRowActive : local.typeRow}
        >{`store`}</div>
      </div>

      <div
        style={{
          height: 300,
          overflow: "scroll",
          border: "0px solid #888",
          alignSelf: "stretch",
          flex: 1,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {type && (
            <input
              style={{ width: 80 }}
              type="text"
              placeholder="🔎 000-000"
              onChange={(e) => setFilter(e.target.value)}
            />
          )}

          {type && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input
                style={{ marginLeft: 10 }}
                type="text"
                placeholder="123-124-123-1233"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div onClick={() => addToGroup(text)} style={local.submitButton}>
                {`+`}
              </div>
            </div>
          )}
        </div>
        <div style={{ height: 20, width: 20 }}>{`--`}</div>
        {showAdded}
      </div>
    </div>
  );
};
export default Groups;

let local = {
  idRow: {
    alignSelf: "stretch",
    height: 30,
    border: "0px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 6,
    paddingLeft: 6,
    display: "flex",
    flexDirection: "row",
  },
  submitButton: {
    height: 30,
    border: "1px solid #aaa",
    paddingTop: 1,
    paddingLeft: 1,
    cursor: "pointer",
    width: 30,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  typeRow: {
    width: 100,
    height: 30,
    border: "1px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    cursor: "pointer",
  },
  typeRowActive: {
    width: 100,
    height: 30,
    border: "1px solid #aaa",
    borderBottomWidth: 1,
    paddingTop: 3,
    paddingLeft: 3,
    color: "#FFF",
    backgroundColor: "#888",
    cursor: "pointer",
  },
};

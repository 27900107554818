export function displayStockRow(data) {}

export function formatDate(date) {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    weekday: "long",
  };
  const dateFormatter = new Intl.DateTimeFormat("en-US", options);
  const parts = dateFormatter.formatToParts(date);

  // Construct the date string with ordinal day.
  const day = parts.find((part) => part.type === "day").value;
  const month = parts.find((part) => part.type === "month").value;
  const year = parts.find((part) => part.type === "year").value;
  const weekday = parts.find((part) => part.type === "weekday").value;

  // Add the ordinal suffix to the day.
  const dayWithSuffix = day + getOrdinalSuffix(day);
  return `${month} ${dayWithSuffix}, ${year} - ${weekday}`;
}

export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export function dayOfWeek(weekNum) {
  let dayOfWeek = "Sun";
  if (weekNum == 1) dayOfWeek = "Mon";
  if (weekNum == 2) dayOfWeek = "Tues";
  if (weekNum == 3) dayOfWeek = "Wed";
  if (weekNum == 4) dayOfWeek = "Thurs";
  if (weekNum == 5) dayOfWeek = "Fri";
  if (weekNum == 6) dayOfWeek = "Sat";
  return dayOfWeek;
}

export function isHoliday(yesterday = false) {
  var dateOptions = {
    timeZone: "America/New_York",
    hour12: false,
    hour: "2-digit",
    hourCycle: "h24",
  };
  let estDate = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  let d = new Date(estDate);

  if (yesterday) {
    d.setDate(d.getDate() - 1);
  }

  let hour = d.getHours();
  let minute = d.getMinutes();
  if (hour < 9 || (hour < 9 && minute < 30)) {
    d.setDate(d.getDate() - 1);
  }

  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();

  if (year == 2023) {
    if (month == 1 && day == 2) return true;
    if (month == 1 && day == 16) return true;
    if (month == 2 && day == 20) return true;
    if (month == 4 && day == 7) return true;
    if (month == 5 && day == 29) return true;
    if (month == 6 && day == 19) return true;
    if (month == 7 && day == 4) return true;
    if (month == 9 && day == 4) return true;
    if (month == 11 && day == 23) return true;
    if (month == 12 && day == 25) return true;
  }
  if (year == 2024) {
    if (month == 1 && day == 1) return true;
    if (month == 1 && day == 15) return true;
    if (month == 2 && day == 19) return true;
    if (month == 3 && day == 29) return true;
    if (month == 5 && day == 27) return true;
    if (month == 6 && day == 19) return true;
    if (month == 7 && day == 4) return true;
    if (month == 9 && day == 2) return true;
    if (month == 11 && day == 28) return true;
    if (month == 12 && day == 25) return true;
  }
  return false;
}

export function nbaTeams() {
  let teams = [
    { name: "Los Angeles Lakers", slug: "los-angeles-lakers", short: "Lakers" },
    { name: "Philadelphia 76ers", slug: "philadelphia-76ers", short: "76ers" },
    { name: "Orlando Magic", slug: "orlando-magic", short: "Magic" },
    {
      name: "Washington Wizards",
      slug: "washington-wizards",
      short: "Wizards",
    },
    { name: "Houston Rockets", slug: "houston-rockets", short: "Rockets" },
    {
      name: "New Orleans Pelicans",
      slug: "new-orleans-pelicans",
      short: "Pelicans",
    },
    { name: "New York Knicks", slug: "new-york-knicks", short: "Knicks" },
    { name: "Chicago Bulls", slug: "chicago-bulls", short: "Bulls" },
    {
      name: "Cleveland Cavaliers",
      slug: "cleveland-cavaliers",
      short: "Cavaliers",
    },
    { name: "Charlotte Hornets", slug: "charlotte-hornets", short: "Hornets" },
    { name: "Denver Nuggets", slug: "denver-nuggets", short: "Nuggets" },
    { name: "Dallas Mavericks", slug: "dallas-mavericks", short: "Mavericks" },
    {
      name: "Oklahoma City Thunder",
      slug: "oklahoma-city-thunder",
      short: "Thunder",
    },
    {
      name: "Portland Trail Blazers",
      slug: "portland-trail-blazers",
      short: "Blazers",
    },
    { name: "Milwaukee Bucks", slug: "milwaukee-bucks", short: "Bucks" },
    {
      name: "Los Angeles Clippers",
      slug: "los-angeles-clippers",
      short: "Clippers",
    },
    { name: "Boston Celtics", slug: "boston-celtics", short: "Celtics" },
    { name: "Phoenix Suns", slug: "phoenix-suns", short: "Suns" },
    { name: "Toronto Raptors", slug: "toronto-raptors", short: "Raptors" },
    { name: "Detroit Pistons", slug: "detroit-pistons", short: "Pistons" },
    {
      name: "Memphis Grizzlies",
      slug: "memphis-grizzlies",
      short: "Grizzlies",
    },
    { name: "Utah Jazz", slug: "utah-jazz", short: "Jazz" },
    { name: "San Antonio Spurs", slug: "san-antonio-spurs", short: "Spurs" },
    {
      name: "Minnesota Timberwolves",
      slug: "minnesota-timberwolves",
      short: "Timberwolves",
    },
    { name: "Sacramento Kings", slug: "sacramento-kings", short: "Kings" },
    { name: "Indiana Pacers", slug: "indiana-pacers", short: "Pacers" },
    { name: "Brooklyn Nets", slug: "brooklyn-nets", short: "Nets" },
    {
      name: "Golden State Warriors",
      slug: "golden-state-warriors",
      short: "Warriors",
    },
    { name: "Atlanta Hawks", slug: "atlanta-hawks", short: "Hawks" },
    { name: "Miami Heat", slug: "miami-heat", short: "Heat" },
  ];
  return teams;
}

export function mlbTeams() {
  let teams = [
    {
      name: "San Francisco Giants",
      slug: "san-francisco-giants",
      short: "Giants",
    },
    { name: "Baltimore Orioles", slug: "baltimore-orioles", short: "Orioles" },
    { name: "Atlanta Braves", slug: "atlanta-braves", short: "Braves" },
    {
      name: "Pittsburgh Pirates",
      slug: "pittsburgh-pirates",
      short: "Pirates",
    },
    { name: "Colorado Rockies", slug: "colorado-rockies", short: "Rockies" },
    { name: "Toronto Blue Jays", slug: "toronto-blue-jays", short: "BlueJays" },
    { name: "Milwaukee Brewers", slug: "milwaukee-brewers", short: "Brewers" },
    { name: "Detroit Tigers", slug: "detroit-tigers", short: "Tigers" },
    { name: "New York Mets", slug: "new-york-mets", short: "Mets" },
    {
      name: "Arizona Diamondbacks",
      slug: "arizona-diamondbacks",
      short: "Diamondbacks",
    },
    {
      name: "Philadelphia Phillies",
      slug: "philadelphia-phillies",
      short: "Phillies",
    },
    { name: "Chicago White Sox", slug: "chicago-white-sox", short: "WhiteSox" },
    {
      name: "Cleveland Guardians",
      slug: "cleveland-guardians",
      short: "Guardians",
    },
    { name: "Kansas City Royals", slug: "kansas-city-royals", short: "Royals" },
    { name: "Minnesota Twins", slug: "minnesota-twins", short: "Twins" },
    { name: "Los Angeles Angels", slug: "los-angeles-angels", short: "Angels" },
    { name: "Texas Rangers", slug: "texas-rangers", short: "Rangers" },
    { name: "Boston Red Sox", slug: "boston-red-sox", short: "RedSox" },
    { name: "San Diego Padres", slug: "san-diego-padres", short: "Padres" },
    { name: "Cincinnati Reds", slug: "cincinnati-reds", short: "Reds" },
    { name: "Miami Marlins", slug: "miami-marlins", short: "Marlins" },
    { name: "Chicago Cubs", slug: "chicago-cubs", short: "Cubs" },
    {
      name: "Los Angeles Dodgers",
      slug: "los-angeles-dodgers",
      short: "Dodgers",
    },
    {
      name: "Oakland Athletics",
      slug: "oakland-athletics",
      short: "Athletics",
    },
    {
      name: "St. Louis Cardinals",
      slug: "st-louis-cardinals",
      short: "Cardinals",
    },
    { name: "Houston Astros", slug: "houston-astros", short: "Astros" },
    { name: "Tampa Bay Rays", slug: "tampa-bay-rays", short: "Rays" },
    { name: "New York Yankees", slug: "new-york-yankees", short: "Yankees" },
    {
      name: "Washington Nationals",
      slug: "washington-nationals",
      short: "Nationals",
    },
    { name: "Seattle Mariners", slug: "seattle-mariners", short: "Mariners" },
  ];
  return teams;
}

export function nhlTeams() {
  let teams = [
    { name: "New Jersey Devils", slug: "new-jersey-devils", short: "Devils" },
    {
      name: "Nashville Predators",
      slug: "nashville-predators",
      short: "Predators",
    },
    {
      name: "Washington Capitals",
      slug: "washington-capitals",
      short: "Capitals",
    },
    { name: "New York Rangers", slug: "new-york-rangers", short: "Rangers" },
    { name: "St. Louis Blues", slug: "st-louis-blues", short: "Blues" },
    { name: "Arizona Coyotes", slug: "arizona-coyotes", short: "Coyotes" },
    { name: "Seattle Kraken", slug: "seattle-kraken", short: "Kraken" },
    {
      name: "New York Islanders",
      slug: "new-york-islanders",
      short: "Islanders",
    },
    {
      name: "Utah Hockey Club",
      slug: "utah-hockey-club",
      short: "Utah",
    },
    {
      name: "Colorado Avalanche",
      slug: "colorado-avalanche",
      short: "Avalanche",
    },
    {
      name: "Pittsburgh Penguins",
      slug: "pittsburgh-penguins",
      short: "Penguins",
    },
    { name: "Boston Bruins", slug: "boston-bruins", short: "Bruins" },
    { name: "Florida Panthers", slug: "florida-panthers", short: "Panthers" },
    {
      name: "Tampa Bay Lightning",
      slug: "tampa-bay-lightning",
      short: "Lightning",
    },
    { name: "San Jose Sharks", slug: "san-jose-sharks", short: "Sharks" },
    { name: "Dallas Stars", slug: "dallas-stars", short: "Stars" },
    {
      name: "Carolina Hurricanes",
      slug: "carolina-hurricanes",
      short: "Hurricanes",
    },
    { name: "Minnesota Wild", slug: "minnesota-wild", short: "Wild" },
    {
      name: "Detroit Red Wings",
      slug: "detroit-red-wings",
      short: "Red Wings",
    },
    { name: "Los Angeles Kings", slug: "los-angeles-kings", short: "Kings" },
    { name: "Vancouver Canucks", slug: "vancouver-canucks", short: "Canucks" },
    {
      name: "Columbus Blue Jackets",
      slug: "columbus-blue-jackets",
      short: "Blue Jackets",
    },
    { name: "Calgary Flames", slug: "calgary-flames", short: "Flames" },
    {
      name: "Toronto Maple Leafs",
      slug: "toronto-maple-leafs",
      short: "Maple Leafs",
    },
    {
      name: "Philadelphia Flyers",
      slug: "philadelphia-flyers",
      short: "Flyers",
    },
    { name: "Edmonton Oilers", slug: "edmonton-oilers", short: "Oilers" },
    { name: "Winnipeg Jets", slug: "winnipeg-jets", short: "Jets" },
    { name: "Ottawa Senators", slug: "ottawa-senators", short: "Senators" },
    {
      name: "Montréal Canadiens",
      slug: "montreal-canadiens",
      short: "Canadiens",
    },
    { name: "Buffalo Sabres", slug: "buffalo-sabres", short: "Sabres" },
    { name: "Anaheim Ducks", slug: "anaheim-ducks", short: "Ducks" },
    {
      name: "Chicago Blackhawks",
      slug: "chicago-blackhawks",
      short: "Blackhawks",
    },
    {
      name: "Vegas Golden Knights",
      slug: "vegas-golden-knights",
      short: "Golden Knights",
    },
    {
      name: "Pacific Division",
      slug: "pacific-division",
      short: "Pacific",
    },
    {
      name: "Central Division",
      slug: "central-division",
      short: "Central",
    },
    {
      name: "Atlantic Division",
      slug: "atlantic-division",
      short: "Atlantic",
    },
    {
      name: "Metropolitan Division",
      slug: "metropolitan-division",
      short: "Metropolitan",
    },
  ];
  return teams;
}

export function nflTeams() {
  let teams = [
    { name: "Buffalo Bills", slug: "buffalo-bills", short: "Bills" },
    {
      name: "Arizona Cardinals",
      slug: "arizona-cardinals",
      short: "Cardinals",
    },
    { name: "Chicago Bears", slug: "chicago-bears", short: "Bears" },
    {
      name: "Jacksonville Jaguars",
      slug: "jacksonville-jaguars",
      short: "Jaguars",
    },
    { name: "Denver Broncos", slug: "denver-broncos", short: "Broncos" },
    { name: "Miami Dolphins", slug: "miami-dolphins", short: "Dolphins" },
    { name: "Indianapolis Colts", slug: "indianapolis-colts", short: "Colts" },
    { name: "New Orleans Saints", slug: "new-orleans-saints", short: "Saints" },
    { name: "Carolina Panthers", slug: "carolina-panthers", short: "Panthers" },
    { name: "Cleveland Browns", slug: "cleveland-browns", short: "Browns" },
    {
      name: "San Francisco 49ers",
      slug: "san-francisco-49ers",
      short: "49ers",
    },
    { name: "New York Jets", slug: "new-york-jets", short: "Jets" },
    { name: "Minnesota Vikings", slug: "minnesota-vikings", short: "Vikings" },
    { name: "Los Angeles Rams", slug: "los-angeles-rams", short: "Rams" },
    {
      name: "Pittsburgh Steelers",
      slug: "pittsburgh-steelers",
      short: "Steelers",
    },
    { name: "Dallas Cowboys", slug: "dallas-cowboys", short: "Cowboys" },
    {
      name: "Los Angeles Chargers",
      slug: "los-angeles-chargers",
      short: "Chargers",
    },
    { name: "Green Bay Packers", slug: "green-bay-packers", short: "Packers" },
    {
      name: "Tampa Bay Buccaneers",
      slug: "tampa-bay-buccaneers",
      short: "Buccaneers",
    },
    { name: "Atlanta Falcons", slug: "atlanta-falcons", short: "Falcons" },
    { name: "Detroit Lions", slug: "detroit-lions", short: "Lions" },
    { name: "Seattle Seahawks", slug: "seattle-seahawks", short: "Seahawks" },
    { name: "New York Giants", slug: "new-york-giants", short: "Giants" },
    {
      name: "Cincinnati Bengals",
      slug: "cincinnati-bengals",
      short: "Bengals",
    },
    { name: "Houston Texans", slug: "houston-texans", short: "Texans" },
    {
      name: "Washington Commanders",
      slug: "washington-commanders",
      short: "Commanders",
    },
    {
      name: "Philadelphia Eagles",
      slug: "philadelphia-eagles",
      short: "Eagles",
    },
    { name: "Las Vegas Raiders", slug: "las-vegas-raiders", short: "Raiders" },
    { name: "Kansas City Chiefs", slug: "kansas-city-chiefs", short: "Chiefs" },
    {
      name: "New England Patriots",
      slug: "new-england-patriots",
      short: "Patriots",
    },
    { name: "Tennessee Titans", slug: "tennessee-titans", short: "Titans" },
    { name: "Baltimore Ravens", slug: "baltimore-ravens", short: "Ravens" },
    {
      name: "AFC Pros",
      slug: "afc-pros",
      short: "AFC Pros",
    },
    {
      name: "NFC Pros",
      slug: "nfc-pros",
      short: "NFC Pros",
    },
  ];
  return teams;
}

export function forexArr() {
  let arr = [
    {
      symbol: "USDEUR",
      name: "U.S. Dollar / Euro",
    },
    {
      symbol: "USDJPY",
      name: "U.S. Dollar / Japanese Yen",
    },
    {
      symbol: "USDAUD",
      name: "U.S. Dollar / Australian Dollar",
    },
    {
      symbol: "USDCZK",
      name: "U.S. Dollar / Czech Koruna",
    },
    {
      symbol: "USDDKK",
      name: "U.S. Dollar / Danish Krone",
    },
    {
      symbol: "USDHKD",
      name: "U.S. Dollar / Hong Kong Dollar",
    },
    {
      symbol: "USDMXN",
      name: "U.S. Dollar / Mexican Peso",
    },
    {
      symbol: "USDNOK",
      name: "U.S. Dollar / Norwegian Krone",
    },
    {
      symbol: "USDNZD",
      name: "U.S. Dollar / New Zealand Dollar",
    },
    {
      symbol: "USDSEK",
      name: "U.S. Dollar / Swedish Krona",
    },
    {
      symbol: "USDSGD",
      name: "U.S. Dollar / Singapore Dollar",
    },
    {
      symbol: "USDGBP",
      name: "U.S. Dollar / Pound Sterling",
    },
    {
      symbol: "USDCNY",
      name: "U.S. Dollar / Chinese Yuan",
    },
    {
      symbol: "USDCHF",
      name: "U.S. Dollar / Swiss Franc",
    },
    {
      symbol: "USDCAD",
      name: "U.S. Dollar / Canadian Dollar",
    },
    {
      symbol: "USDKRW",
      name: "U.S. Dollar / South Korean Won",
    },
    {
      symbol: "USDZAR",
      name: "U.S. Dollar / South African Rand",
    },
    {
      symbol: "USDBRL",
      name: "U.S. Dollar / Brazilian Real",
    },
    {
      symbol: "USDRUB",
      name: "U.S. Dollar / Russian Ruble",
    },
    {
      symbol: "USDTRY",
      name: "U.S. Dollar / Turkish Lira",
    },
    {
      symbol: "USDINR",
      name: "U.S. Dollar / Indian Rupee",
    },
    {
      symbol: "USDPLN",
      name: "U.S. Dollar / Polish Zloty",
    },
    {
      symbol: "USDTHB",
      name: "U.S. Dollar / Thai Baht",
    },
    {
      symbol: "EURUSD",
      name: "Euro / U.S. Dollar",
    },
    {
      symbol: "EURJPY",
      name: "Euro / Japanese Yen",
    },
    {
      symbol: "EURAUD",
      name: "Euro / Australian Dollar",
    },
    {
      symbol: "EURCZK",
      name: "Euro / Czech Koruna",
    },
    {
      symbol: "EURDKK",
      name: "Euro / Danish Krone",
    },
    {
      symbol: "EURHKD",
      name: "Euro / Hong Kong Dollar",
    },
    {
      symbol: "EURMXN",
      name: "Euro / Mexican Peso",
    },
    {
      symbol: "EURNOK",
      name: "Euro / Norwegian Krone",
    },
    {
      symbol: "EURNZD",
      name: "Euro / New Zealand Dollar",
    },
    {
      symbol: "EURSEK",
      name: "Euro / Swedish Krona",
    },
    {
      symbol: "EURSGD",
      name: "Euro / Singapore Dollar",
    },
    {
      symbol: "EURGBP",
      name: "Euro / Pound Sterling",
    },
    {
      symbol: "EURCNY",
      name: "Euro / Chinese Yuan",
    },
    {
      symbol: "EURCHF",
      name: "Euro / Swiss Franc",
    },
    {
      symbol: "EURCAD",
      name: "Euro / Canadian Dollar",
    },
    {
      symbol: "EURKRW",
      name: "Euro / South Korean Won",
    },
    {
      symbol: "EURZAR",
      name: "Euro / South African Rand",
    },
    {
      symbol: "EURBRL",
      name: "Euro / Brazilian Real",
    },
    {
      symbol: "EURRUB",
      name: "Euro / Russian Ruble",
    },
    {
      symbol: "EURTRY",
      name: "Euro / Turkish Lira",
    },
    {
      symbol: "EURINR",
      name: "Euro / Indian Rupee",
    },
    {
      symbol: "EURPLN",
      name: "Euro / Polish Zloty",
    },
    {
      symbol: "EURTHB",
      name: "Euro / Thai Baht",
    },
    {
      symbol: "JPYUSD",
      name: "Japanese Yen / U.S. Dollar",
    },
    {
      symbol: "JPYEUR",
      name: "Japanese Yen / Euro",
    },
    {
      symbol: "JPYAUD",
      name: "Japanese Yen / Australian Dollar",
    },
    {
      symbol: "JPYCZK",
      name: "Japanese Yen / Czech Koruna",
    },
    {
      symbol: "JPYDKK",
      name: "Japanese Yen / Danish Krone",
    },
    {
      symbol: "JPYHKD",
      name: "Japanese Yen / Hong Kong Dollar",
    },
    {
      symbol: "JPYMXN",
      name: "Japanese Yen / Mexican Peso",
    },
    {
      symbol: "JPYNOK",
      name: "Japanese Yen / Norwegian Krone",
    },
    {
      symbol: "JPYNZD",
      name: "Japanese Yen / New Zealand Dollar",
    },
    {
      symbol: "JPYSEK",
      name: "Japanese Yen / Swedish Krona",
    },
    {
      symbol: "JPYSGD",
      name: "Japanese Yen / Singapore Dollar",
    },
    {
      symbol: "JPYGBP",
      name: "Japanese Yen / Pound Sterling",
    },
    {
      symbol: "JPYCNY",
      name: "Japanese Yen / Chinese Yuan",
    },
    {
      symbol: "JPYCHF",
      name: "Japanese Yen / Swiss Franc",
    },
    {
      symbol: "JPYCAD",
      name: "Japanese Yen / Canadian Dollar",
    },
    {
      symbol: "JPYKRW",
      name: "Japanese Yen / South Korean Won",
    },
    {
      symbol: "JPYZAR",
      name: "Japanese Yen / South African Rand",
    },
    {
      symbol: "JPYBRL",
      name: "Japanese Yen / Brazilian Real",
    },
    {
      symbol: "JPYRUB",
      name: "Japanese Yen / Russian Ruble",
    },
    {
      symbol: "JPYTRY",
      name: "Japanese Yen / Turkish Lira",
    },
    {
      symbol: "JPYINR",
      name: "Japanese Yen / Indian Rupee",
    },
    {
      symbol: "JPYPLN",
      name: "Japanese Yen / Polish Zloty",
    },
    {
      symbol: "JPYTHB",
      name: "Japanese Yen / Thai Baht",
    },
    {
      symbol: "AUDUSD",
      name: "Australian Dollar / U.S. Dollar",
    },
    {
      symbol: "AUDEUR",
      name: "Australian Dollar / Euro",
    },
    {
      symbol: "AUDJPY",
      name: "Australian Dollar / Japanese Yen",
    },
    {
      symbol: "AUDCZK",
      name: "Australian Dollar / Czech Koruna",
    },
    {
      symbol: "AUDDKK",
      name: "Australian Dollar / Danish Krone",
    },
    {
      symbol: "AUDHKD",
      name: "Australian Dollar / Hong Kong Dollar",
    },
    {
      symbol: "AUDMXN",
      name: "Australian Dollar / Mexican Peso",
    },
    {
      symbol: "AUDNOK",
      name: "Australian Dollar / Norwegian Krone",
    },
    {
      symbol: "AUDNZD",
      name: "Australian Dollar / New Zealand Dollar",
    },
    {
      symbol: "AUDSEK",
      name: "Australian Dollar / Swedish Krona",
    },
    {
      symbol: "AUDSGD",
      name: "Australian Dollar / Singapore Dollar",
    },
    {
      symbol: "AUDGBP",
      name: "Australian Dollar / Pound Sterling",
    },
    {
      symbol: "AUDCNY",
      name: "Australian Dollar / Chinese Yuan",
    },
    {
      symbol: "AUDCHF",
      name: "Australian Dollar / Swiss Franc",
    },
    {
      symbol: "AUDCAD",
      name: "Australian Dollar / Canadian Dollar",
    },
    {
      symbol: "AUDKRW",
      name: "Australian Dollar / South Korean Won",
    },
    {
      symbol: "AUDZAR",
      name: "Australian Dollar / South African Rand",
    },
    {
      symbol: "AUDBRL",
      name: "Australian Dollar / Brazilian Real",
    },
    {
      symbol: "AUDRUB",
      name: "Australian Dollar / Russian Ruble",
    },
    {
      symbol: "AUDTRY",
      name: "Australian Dollar / Turkish Lira",
    },
    {
      symbol: "AUDINR",
      name: "Australian Dollar / Indian Rupee",
    },
    {
      symbol: "AUDPLN",
      name: "Australian Dollar / Polish Zloty",
    },
    {
      symbol: "AUDTHB",
      name: "Australian Dollar / Thai Baht",
    },
    {
      symbol: "CZKUSD",
      name: "Czech Koruna / U.S. Dollar",
    },
    {
      symbol: "CZKEUR",
      name: "Czech Koruna / Euro",
    },
    {
      symbol: "CZKJPY",
      name: "Czech Koruna / Japanese Yen",
    },
    {
      symbol: "CZKAUD",
      name: "Czech Koruna / Australian Dollar",
    },
    {
      symbol: "CZKDKK",
      name: "Czech Koruna / Danish Krone",
    },
    {
      symbol: "CZKHKD",
      name: "Czech Koruna / Hong Kong Dollar",
    },
    {
      symbol: "CZKMXN",
      name: "Czech Koruna / Mexican Peso",
    },
    {
      symbol: "CZKNOK",
      name: "Czech Koruna / Norwegian Krone",
    },
    {
      symbol: "CZKNZD",
      name: "Czech Koruna / New Zealand Dollar",
    },
    {
      symbol: "CZKSEK",
      name: "Czech Koruna / Swedish Krona",
    },
    {
      symbol: "CZKSGD",
      name: "Czech Koruna / Singapore Dollar",
    },
    {
      symbol: "CZKGBP",
      name: "Czech Koruna / Pound Sterling",
    },
    {
      symbol: "CZKCNY",
      name: "Czech Koruna / Chinese Yuan",
    },
    {
      symbol: "CZKCHF",
      name: "Czech Koruna / Swiss Franc",
    },
    {
      symbol: "CZKCAD",
      name: "Czech Koruna / Canadian Dollar",
    },
    {
      symbol: "CZKZAR",
      name: "Czech Koruna / South African Rand",
    },
    {
      symbol: "CZKBRL",
      name: "Czech Koruna / Brazilian Real",
    },
    {
      symbol: "CZKRUB",
      name: "Czech Koruna / Russian Ruble",
    },
    {
      symbol: "CZKTRY",
      name: "Czech Koruna / Turkish Lira",
    },
    {
      symbol: "CZKINR",
      name: "Czech Koruna / Indian Rupee",
    },
    {
      symbol: "CZKPLN",
      name: "Czech Koruna / Polish Zloty",
    },
    {
      symbol: "DKKUSD",
      name: "Danish Krone / U.S. Dollar",
    },
    {
      symbol: "DKKEUR",
      name: "Danish Krone / Euro",
    },
    {
      symbol: "DKKJPY",
      name: "Danish Krone / Japanese Yen",
    },
    {
      symbol: "DKKAUD",
      name: "Danish Krone / Australian Dollar",
    },
    {
      symbol: "DKKCZK",
      name: "Danish Krone / Czech Koruna",
    },
    {
      symbol: "DKKHKD",
      name: "Danish Krone / Hong Kong Dollar",
    },
    {
      symbol: "DKKMXN",
      name: "Danish Krone / Mexican Peso",
    },
    {
      symbol: "DKKNOK",
      name: "Danish Krone / Norwegian Krone",
    },
    {
      symbol: "DKKNZD",
      name: "Danish Krone / New Zealand Dollar",
    },
    {
      symbol: "DKKSEK",
      name: "Danish Krone / Swedish Krona",
    },
    {
      symbol: "DKKSGD",
      name: "Danish Krone / Singapore Dollar",
    },
    {
      symbol: "DKKGBP",
      name: "Danish Krone / Pound Sterling",
    },
    {
      symbol: "DKKCNY",
      name: "Danish Krone / Chinese Yuan",
    },
    {
      symbol: "DKKCHF",
      name: "Danish Krone / Swiss Franc",
    },
    {
      symbol: "DKKCAD",
      name: "Danish Krone / Canadian Dollar",
    },
    {
      symbol: "DKKKRW",
      name: "Danish Krone / South Korean Won",
    },
    {
      symbol: "DKKZAR",
      name: "Danish Krone / South African Rand",
    },
    {
      symbol: "DKKBRL",
      name: "Danish Krone / Brazilian Real",
    },
    {
      symbol: "DKKRUB",
      name: "Danish Krone / Russian Ruble",
    },
    {
      symbol: "DKKTRY",
      name: "Danish Krone / Turkish Lira",
    },
    {
      symbol: "DKKINR",
      name: "Danish Krone / Indian Rupee",
    },
    {
      symbol: "DKKPLN",
      name: "Danish Krone / Polish Zloty",
    },
    {
      symbol: "DKKTHB",
      name: "Danish Krone / Thai Baht",
    },
    {
      symbol: "HKDUSD",
      name: "Hong Kong Dollar / U.S. Dollar",
    },
    {
      symbol: "HKDEUR",
      name: "Hong Kong Dollar / Euro",
    },
    {
      symbol: "HKDJPY",
      name: "Hong Kong Dollar / Japanese Yen",
    },
    {
      symbol: "HKDAUD",
      name: "Hong Kong Dollar / Australian Dollar",
    },
    {
      symbol: "HKDCZK",
      name: "Hong Kong Dollar / Czech Koruna",
    },
    {
      symbol: "HKDDKK",
      name: "Hong Kong Dollar / Danish Krone",
    },
    {
      symbol: "HKDMXN",
      name: "Hong Kong Dollar / Mexican Peso",
    },
    {
      symbol: "HKDNOK",
      name: "Hong Kong Dollar / Norwegian Krone",
    },
    {
      symbol: "HKDNZD",
      name: "Hong Kong Dollar / New Zealand Dollar",
    },
    {
      symbol: "HKDSEK",
      name: "Hong Kong Dollar / Swedish Krona",
    },
    {
      symbol: "HKDSGD",
      name: "Hong Kong Dollar / Singapore Dollar",
    },
    {
      symbol: "HKDGBP",
      name: "Hong Kong Dollar / Pound Sterling",
    },
    {
      symbol: "HKDCNY",
      name: "Hong Kong Dollar / Chinese Yuan",
    },
    {
      symbol: "HKDCHF",
      name: "Hong Kong Dollar / Swiss Franc",
    },
    {
      symbol: "HKDCAD",
      name: "Hong Kong Dollar / Canadian Dollar",
    },
    {
      symbol: "HKDKRW",
      name: "Hong Kong Dollar / South Korean Won",
    },
    {
      symbol: "HKDZAR",
      name: "Hong Kong Dollar / South African Rand",
    },
    {
      symbol: "HKDBRL",
      name: "Hong Kong Dollar / Brazilian Real",
    },
    {
      symbol: "HKDRUB",
      name: "Hong Kong Dollar / Russian Ruble",
    },
    {
      symbol: "HKDTRY",
      name: "Hong Kong Dollar / Turkish Lira",
    },
    {
      symbol: "HKDINR",
      name: "Hong Kong Dollar / Indian Rupee",
    },
    {
      symbol: "HKDPLN",
      name: "Hong Kong Dollar / Polish Zloty",
    },
    {
      symbol: "HKDTHB",
      name: "Hong Kong Dollar / Thai Baht",
    },
    {
      symbol: "MXNUSD",
      name: "Mexican Peso / U.S. Dollar",
    },
    {
      symbol: "MXNEUR",
      name: "Mexican Peso / Euro",
    },
    {
      symbol: "MXNJPY",
      name: "Mexican Peso / Japanese Yen",
    },
    {
      symbol: "MXNAUD",
      name: "Mexican Peso / Australian Dollar",
    },
    {
      symbol: "MXNCZK",
      name: "Mexican Peso / Czech Koruna",
    },
    {
      symbol: "MXNDKK",
      name: "Mexican Peso / Danish Krone",
    },
    {
      symbol: "MXNHKD",
      name: "Mexican Peso / Hong Kong Dollar",
    },
    {
      symbol: "MXNNOK",
      name: "Mexican Peso / Norwegian Krone",
    },
    {
      symbol: "MXNNZD",
      name: "Mexican Peso / New Zealand Dollar",
    },
    {
      symbol: "MXNSEK",
      name: "Mexican Peso / Swedish Krona",
    },
    {
      symbol: "MXNSGD",
      name: "Mexican Peso / Singapore Dollar",
    },
    {
      symbol: "MXNGBP",
      name: "Mexican Peso / Pound Sterling",
    },
    {
      symbol: "MXNCNY",
      name: "Mexican Peso / Chinese Yuan",
    },
    {
      symbol: "MXNCHF",
      name: "Mexican Peso / Swiss Franc",
    },
    {
      symbol: "MXNCAD",
      name: "Mexican Peso / Canadian Dollar",
    },
    {
      symbol: "MXNZAR",
      name: "Mexican Peso / South African Rand",
    },
    {
      symbol: "MXNBRL",
      name: "Mexican Peso / Brazilian Real",
    },
    {
      symbol: "MXNRUB",
      name: "Mexican Peso / Russian Ruble",
    },
    {
      symbol: "MXNTRY",
      name: "Mexican Peso / Turkish Lira",
    },
    {
      symbol: "MXNINR",
      name: "Mexican Peso / Indian Rupee",
    },
    {
      symbol: "MXNPLN",
      name: "Mexican Peso / Polish Zloty",
    },
    {
      symbol: "NOKUSD",
      name: "Norwegian Krone / U.S. Dollar",
    },
    {
      symbol: "NOKEUR",
      name: "Norwegian Krone / Euro",
    },
    {
      symbol: "NOKJPY",
      name: "Norwegian Krone / Japanese Yen",
    },
    {
      symbol: "NOKAUD",
      name: "Norwegian Krone / Australian Dollar",
    },
    {
      symbol: "NOKCZK",
      name: "Norwegian Krone / Czech Koruna",
    },
    {
      symbol: "NOKDKK",
      name: "Norwegian Krone / Danish Krone",
    },
    {
      symbol: "NOKHKD",
      name: "Norwegian Krone / Hong Kong Dollar",
    },
    {
      symbol: "NOKMXN",
      name: "Norwegian Krone / Mexican Peso",
    },
    {
      symbol: "NOKNZD",
      name: "Norwegian Krone / New Zealand Dollar",
    },
    {
      symbol: "NOKSEK",
      name: "Norwegian Krone / Swedish Krona",
    },
    {
      symbol: "NOKSGD",
      name: "Norwegian Krone / Singapore Dollar",
    },
    {
      symbol: "NOKGBP",
      name: "Norwegian Krone / Pound Sterling",
    },
    {
      symbol: "NOKCNY",
      name: "Norwegian Krone / Chinese Yuan",
    },
    {
      symbol: "NOKCHF",
      name: "Norwegian Krone / Swiss Franc",
    },
    {
      symbol: "NOKCAD",
      name: "Norwegian Krone / Canadian Dollar",
    },
    {
      symbol: "NOKZAR",
      name: "Norwegian Krone / South African Rand",
    },
    {
      symbol: "NOKBRL",
      name: "Norwegian Krone / Brazilian Real",
    },
    {
      symbol: "NOKRUB",
      name: "Norwegian Krone / Russian Ruble",
    },
    {
      symbol: "NOKTRY",
      name: "Norwegian Krone / Turkish Lira",
    },
    {
      symbol: "NOKINR",
      name: "Norwegian Krone / Indian Rupee",
    },
    {
      symbol: "NOKPLN",
      name: "Norwegian Krone / Polish Zloty",
    },
    {
      symbol: "NZDUSD",
      name: "New Zealand Dollar / U.S. Dollar",
    },
    {
      symbol: "NZDEUR",
      name: "New Zealand Dollar / Euro",
    },
    {
      symbol: "NZDJPY",
      name: "New Zealand Dollar / Japanese Yen",
    },
    {
      symbol: "NZDAUD",
      name: "New Zealand Dollar / Australian Dollar",
    },
    {
      symbol: "NZDCZK",
      name: "New Zealand Dollar / Czech Koruna",
    },
    {
      symbol: "NZDDKK",
      name: "New Zealand Dollar / Danish Krone",
    },
    {
      symbol: "NZDHKD",
      name: "New Zealand Dollar / Hong Kong Dollar",
    },
    {
      symbol: "NZDMXN",
      name: "New Zealand Dollar / Mexican Peso",
    },
    {
      symbol: "NZDNOK",
      name: "New Zealand Dollar / Norwegian Krone",
    },
    {
      symbol: "NZDSEK",
      name: "New Zealand Dollar / Swedish Krona",
    },
    {
      symbol: "NZDSGD",
      name: "New Zealand Dollar / Singapore Dollar",
    },
    {
      symbol: "NZDGBP",
      name: "New Zealand Dollar / Pound Sterling",
    },
    {
      symbol: "NZDCNY",
      name: "New Zealand Dollar / Chinese Yuan",
    },
    {
      symbol: "NZDCHF",
      name: "New Zealand Dollar / Swiss Franc",
    },
    {
      symbol: "NZDCAD",
      name: "New Zealand Dollar / Canadian Dollar",
    },
    {
      symbol: "NZDKRW",
      name: "New Zealand Dollar / South Korean Won",
    },
    {
      symbol: "NZDZAR",
      name: "New Zealand Dollar / South African Rand",
    },
    {
      symbol: "NZDBRL",
      name: "New Zealand Dollar / Brazilian Real",
    },
    {
      symbol: "NZDRUB",
      name: "New Zealand Dollar / Russian Ruble",
    },
    {
      symbol: "NZDTRY",
      name: "New Zealand Dollar / Turkish Lira",
    },
    {
      symbol: "NZDINR",
      name: "New Zealand Dollar / Indian Rupee",
    },
    {
      symbol: "NZDPLN",
      name: "New Zealand Dollar / Polish Zloty",
    },
    {
      symbol: "NZDTHB",
      name: "New Zealand Dollar / Thai Baht",
    },
    {
      symbol: "SEKUSD",
      name: "Swedish Krona / U.S. Dollar",
    },
    {
      symbol: "SEKEUR",
      name: "Swedish Krona / Euro",
    },
    {
      symbol: "SEKJPY",
      name: "Swedish Krona / Japanese Yen",
    },
    {
      symbol: "SEKAUD",
      name: "Swedish Krona / Australian Dollar",
    },
    {
      symbol: "SEKCZK",
      name: "Swedish Krona / Czech Koruna",
    },
    {
      symbol: "SEKDKK",
      name: "Swedish Krona / Danish Krone",
    },
    {
      symbol: "SEKHKD",
      name: "Swedish Krona / Hong Kong Dollar",
    },
    {
      symbol: "SEKMXN",
      name: "Swedish Krona / Mexican Peso",
    },
    {
      symbol: "SEKNOK",
      name: "Swedish Krona / Norwegian Krone",
    },
    {
      symbol: "SEKNZD",
      name: "Swedish Krona / New Zealand Dollar",
    },
    {
      symbol: "SEKSGD",
      name: "Swedish Krona / Singapore Dollar",
    },
    {
      symbol: "SEKGBP",
      name: "Swedish Krona / Pound Sterling",
    },
    {
      symbol: "SEKCNY",
      name: "Swedish Krona / Chinese Yuan",
    },
    {
      symbol: "SEKCHF",
      name: "Swedish Krona / Swiss Franc",
    },
    {
      symbol: "SEKCAD",
      name: "Swedish Krona / Canadian Dollar",
    },
    {
      symbol: "SEKKRW",
      name: "Swedish Krona / South Korean Won",
    },
    {
      symbol: "SEKZAR",
      name: "Swedish Krona / South African Rand",
    },
    {
      symbol: "SEKBRL",
      name: "Swedish Krona / Brazilian Real",
    },
    {
      symbol: "SEKRUB",
      name: "Swedish Krona / Russian Ruble",
    },
    {
      symbol: "SEKTRY",
      name: "Swedish Krona / Turkish Lira",
    },
    {
      symbol: "SEKINR",
      name: "Swedish Krona / Indian Rupee",
    },
    {
      symbol: "SEKPLN",
      name: "Swedish Krona / Polish Zloty",
    },
    {
      symbol: "SGDUSD",
      name: "Singapore Dollar / U.S. Dollar",
    },
    {
      symbol: "SGDEUR",
      name: "Singapore Dollar / Euro",
    },
    {
      symbol: "SGDJPY",
      name: "Singapore Dollar / Japanese Yen",
    },
    {
      symbol: "SGDAUD",
      name: "Singapore Dollar / Australian Dollar",
    },
    {
      symbol: "SGDCZK",
      name: "Singapore Dollar / Czech Koruna",
    },
    {
      symbol: "SGDDKK",
      name: "Singapore Dollar / Danish Krone",
    },
    {
      symbol: "SGDHKD",
      name: "Singapore Dollar / Hong Kong Dollar",
    },
    {
      symbol: "SGDMXN",
      name: "Singapore Dollar / Mexican Peso",
    },
    {
      symbol: "SGDNOK",
      name: "Singapore Dollar / Norwegian Krone",
    },
    {
      symbol: "SGDNZD",
      name: "Singapore Dollar / New Zealand Dollar",
    },
    {
      symbol: "SGDSEK",
      name: "Singapore Dollar / Swedish Krona",
    },
    {
      symbol: "SGDGBP",
      name: "Singapore Dollar / Pound Sterling",
    },
    {
      symbol: "SGDCNY",
      name: "Singapore Dollar / Chinese Yuan",
    },
    {
      symbol: "SGDCHF",
      name: "Singapore Dollar / Swiss Franc",
    },
    {
      symbol: "SGDCAD",
      name: "Singapore Dollar / Canadian Dollar",
    },
    {
      symbol: "SGDKRW",
      name: "Singapore Dollar / South Korean Won",
    },
    {
      symbol: "SGDZAR",
      name: "Singapore Dollar / South African Rand",
    },
    {
      symbol: "SGDBRL",
      name: "Singapore Dollar / Brazilian Real",
    },
    {
      symbol: "SGDRUB",
      name: "Singapore Dollar / Russian Ruble",
    },
    {
      symbol: "SGDTRY",
      name: "Singapore Dollar / Turkish Lira",
    },
    {
      symbol: "SGDINR",
      name: "Singapore Dollar / Indian Rupee",
    },
    {
      symbol: "SGDPLN",
      name: "Singapore Dollar / Polish Zloty",
    },
    {
      symbol: "SGDTHB",
      name: "Singapore Dollar / Thai Baht",
    },
    {
      symbol: "GBPUSD",
      name: "Pound Sterling / U.S. Dollar",
    },
    {
      symbol: "GBPEUR",
      name: "Pound Sterling / Euro",
    },
    {
      symbol: "GBPJPY",
      name: "Pound Sterling / Japanese Yen",
    },
    {
      symbol: "GBPAUD",
      name: "Pound Sterling / Australian Dollar",
    },
    {
      symbol: "GBPCZK",
      name: "Pound Sterling / Czech Koruna",
    },
    {
      symbol: "GBPDKK",
      name: "Pound Sterling / Danish Krone",
    },
    {
      symbol: "GBPHKD",
      name: "Pound Sterling / Hong Kong Dollar",
    },
    {
      symbol: "GBPMXN",
      name: "Pound Sterling / Mexican Peso",
    },
    {
      symbol: "GBPNOK",
      name: "Pound Sterling / Norwegian Krone",
    },
    {
      symbol: "GBPNZD",
      name: "Pound Sterling / New Zealand Dollar",
    },
    {
      symbol: "GBPSEK",
      name: "Pound Sterling / Swedish Krona",
    },
    {
      symbol: "GBPSGD",
      name: "Pound Sterling / Singapore Dollar",
    },
    {
      symbol: "GBPCNY",
      name: "Pound Sterling / Chinese Yuan",
    },
    {
      symbol: "GBPCHF",
      name: "Pound Sterling / Swiss Franc",
    },
    {
      symbol: "GBPCAD",
      name: "Pound Sterling / Canadian Dollar",
    },
    {
      symbol: "GBPKRW",
      name: "Pound Sterling / South Korean Won",
    },
    {
      symbol: "GBPZAR",
      name: "Pound Sterling / South African Rand",
    },
    {
      symbol: "GBPBRL",
      name: "Pound Sterling / Brazilian Real",
    },
    {
      symbol: "GBPRUB",
      name: "Pound Sterling / Russian Ruble",
    },
    {
      symbol: "GBPTRY",
      name: "Pound Sterling / Turkish Lira",
    },
    {
      symbol: "GBPINR",
      name: "Pound Sterling / Indian Rupee",
    },
    {
      symbol: "GBPPLN",
      name: "Pound Sterling / Polish Zloty",
    },
    {
      symbol: "GBPTHB",
      name: "Pound Sterling / Thai Baht",
    },
    {
      symbol: "CNYUSD",
      name: "Chinese Yuan / U.S. Dollar",
    },
    {
      symbol: "CNYEUR",
      name: "Chinese Yuan / Euro",
    },
    {
      symbol: "CNYJPY",
      name: "Chinese Yuan / Japanese Yen",
    },
    {
      symbol: "CNYAUD",
      name: "Chinese Yuan / Australian Dollar",
    },
    {
      symbol: "CNYCZK",
      name: "Chinese Yuan / Czech Koruna",
    },
    {
      symbol: "CNYDKK",
      name: "Chinese Yuan / Danish Krone",
    },
    {
      symbol: "CNYHKD",
      name: "Chinese Yuan / Hong Kong Dollar",
    },
    {
      symbol: "CNYMXN",
      name: "Chinese Yuan / Mexican Peso",
    },
    {
      symbol: "CNYNOK",
      name: "Chinese Yuan / Norwegian Krone",
    },
    {
      symbol: "CNYNZD",
      name: "Chinese Yuan / New Zealand Dollar",
    },
    {
      symbol: "CNYSEK",
      name: "Chinese Yuan / Swedish Krona",
    },
    {
      symbol: "CNYSGD",
      name: "Chinese Yuan / Singapore Dollar",
    },
    {
      symbol: "CNYGBP",
      name: "Chinese Yuan / Pound Sterling",
    },
    {
      symbol: "CNYCHF",
      name: "Chinese Yuan / Swiss Franc",
    },
    {
      symbol: "CNYCAD",
      name: "Chinese Yuan / Canadian Dollar",
    },
    {
      symbol: "CNYKRW",
      name: "Chinese Yuan / South Korean Won",
    },
    {
      symbol: "CNYZAR",
      name: "Chinese Yuan / South African Rand",
    },
    {
      symbol: "CNYBRL",
      name: "Chinese Yuan / Brazilian Real",
    },
    {
      symbol: "CNYRUB",
      name: "Chinese Yuan / Russian Ruble",
    },
    {
      symbol: "CNYTRY",
      name: "Chinese Yuan / Turkish Lira",
    },
    {
      symbol: "CNYINR",
      name: "Chinese Yuan / Indian Rupee",
    },
    {
      symbol: "CNYPLN",
      name: "Chinese Yuan / Polish Zloty",
    },
    {
      symbol: "CNYTHB",
      name: "Chinese Yuan / Thai Baht",
    },
    {
      symbol: "CHFUSD",
      name: "Swiss Franc / U.S. Dollar",
    },
    {
      symbol: "CHFEUR",
      name: "Swiss Franc / Euro",
    },
    {
      symbol: "CHFJPY",
      name: "Swiss Franc / Japanese Yen",
    },
    {
      symbol: "CHFAUD",
      name: "Swiss Franc / Australian Dollar",
    },
    {
      symbol: "CHFCZK",
      name: "Swiss Franc / Czech Koruna",
    },
    {
      symbol: "CHFDKK",
      name: "Swiss Franc / Danish Krone",
    },
    {
      symbol: "CHFHKD",
      name: "Swiss Franc / Hong Kong Dollar",
    },
    {
      symbol: "CHFMXN",
      name: "Swiss Franc / Mexican Peso",
    },
    {
      symbol: "CHFNOK",
      name: "Swiss Franc / Norwegian Krone",
    },
    {
      symbol: "CHFNZD",
      name: "Swiss Franc / New Zealand Dollar",
    },
    {
      symbol: "CHFSEK",
      name: "Swiss Franc / Swedish Krona",
    },
    {
      symbol: "CHFSGD",
      name: "Swiss Franc / Singapore Dollar",
    },
    {
      symbol: "CHFGBP",
      name: "Swiss Franc / Pound Sterling",
    },
    {
      symbol: "CHFCNY",
      name: "Swiss Franc / Chinese Yuan",
    },
    {
      symbol: "CHFCAD",
      name: "Swiss Franc / Canadian Dollar",
    },
    {
      symbol: "CHFKRW",
      name: "Swiss Franc / South Korean Won",
    },
    {
      symbol: "CHFZAR",
      name: "Swiss Franc / South African Rand",
    },
    {
      symbol: "CHFBRL",
      name: "Swiss Franc / Brazilian Real",
    },
    {
      symbol: "CHFRUB",
      name: "Swiss Franc / Russian Ruble",
    },
    {
      symbol: "CHFTRY",
      name: "Swiss Franc / Turkish Lira",
    },
    {
      symbol: "CHFINR",
      name: "Swiss Franc / Indian Rupee",
    },
    {
      symbol: "CHFPLN",
      name: "Swiss Franc / Polish Zloty",
    },
    {
      symbol: "CHFTHB",
      name: "Swiss Franc / Thai Baht",
    },
    {
      symbol: "CADUSD",
      name: "Canadian Dollar / U.S. Dollar",
    },
    {
      symbol: "CADEUR",
      name: "Canadian Dollar / Euro",
    },
    {
      symbol: "CADJPY",
      name: "Canadian Dollar / Japanese Yen",
    },
    {
      symbol: "CADAUD",
      name: "Canadian Dollar / Australian Dollar",
    },
    {
      symbol: "CADCZK",
      name: "Canadian Dollar / Czech Koruna",
    },
    {
      symbol: "CADDKK",
      name: "Canadian Dollar / Danish Krone",
    },
    {
      symbol: "CADHKD",
      name: "Canadian Dollar / Hong Kong Dollar",
    },
    {
      symbol: "CADMXN",
      name: "Canadian Dollar / Mexican Peso",
    },
    {
      symbol: "CADNOK",
      name: "Canadian Dollar / Norwegian Krone",
    },
    {
      symbol: "CADNZD",
      name: "Canadian Dollar / New Zealand Dollar",
    },
    {
      symbol: "CADSEK",
      name: "Canadian Dollar / Swedish Krona",
    },
    {
      symbol: "CADSGD",
      name: "Canadian Dollar / Singapore Dollar",
    },
    {
      symbol: "CADGBP",
      name: "Canadian Dollar / Pound Sterling",
    },
    {
      symbol: "CADCNY",
      name: "Canadian Dollar / Chinese Yuan",
    },
    {
      symbol: "CADCHF",
      name: "Canadian Dollar / Swiss Franc",
    },
    {
      symbol: "CADKRW",
      name: "Canadian Dollar / South Korean Won",
    },
    {
      symbol: "CADZAR",
      name: "Canadian Dollar / South African Rand",
    },
    {
      symbol: "CADBRL",
      name: "Canadian Dollar / Brazilian Real",
    },
    {
      symbol: "CADRUB",
      name: "Canadian Dollar / Russian Ruble",
    },
    {
      symbol: "CADTRY",
      name: "Canadian Dollar / Turkish Lira",
    },
    {
      symbol: "CADINR",
      name: "Canadian Dollar / Indian Rupee",
    },
    {
      symbol: "CADPLN",
      name: "Canadian Dollar / Polish Zloty",
    },
    {
      symbol: "CADTHB",
      name: "Canadian Dollar / Thai Baht",
    },
    {
      symbol: "KRWUSD",
      name: "South Korean Won / U.S. Dollar",
    },
    {
      symbol: "KRWEUR",
      name: "South Korean Won / Euro",
    },
    {
      symbol: "KRWJPY",
      name: "South Korean Won / Japanese Yen",
    },
    {
      symbol: "KRWAUD",
      name: "South Korean Won / Australian Dollar",
    },
    {
      symbol: "KRWHKD",
      name: "South Korean Won / Hong Kong Dollar",
    },
    {
      symbol: "KRWNZD",
      name: "South Korean Won / New Zealand Dollar",
    },
    {
      symbol: "KRWSEK",
      name: "South Korean Won / Swedish Krona",
    },
    {
      symbol: "KRWSGD",
      name: "South Korean Won / Singapore Dollar",
    },
    {
      symbol: "KRWGBP",
      name: "South Korean Won / Pound Sterling",
    },
    {
      symbol: "KRWCNY",
      name: "South Korean Won / Chinese Yuan",
    },
    {
      symbol: "KRWCHF",
      name: "South Korean Won / Swiss Franc",
    },
    {
      symbol: "KRWCAD",
      name: "South Korean Won / Canadian Dollar",
    },
    {
      symbol: "KRWZAR",
      name: "South Korean Won / South African Rand",
    },
    {
      symbol: "KRWBRL",
      name: "South Korean Won / Brazilian Real",
    },
    {
      symbol: "KRWRUB",
      name: "South Korean Won / Russian Ruble",
    },
    {
      symbol: "KRWINR",
      name: "South Korean Won / Indian Rupee",
    },
    {
      symbol: "KRWTHB",
      name: "South Korean Won / Thai Baht",
    },
    {
      symbol: "ZARUSD",
      name: "South African Rand / U.S. Dollar",
    },
    {
      symbol: "ZAREUR",
      name: "South African Rand / Euro",
    },
    {
      symbol: "ZARJPY",
      name: "South African Rand / Japanese Yen",
    },
    {
      symbol: "ZARAUD",
      name: "South African Rand / Australian Dollar",
    },
    {
      symbol: "ZARCZK",
      name: "South African Rand / Czech Koruna",
    },
    {
      symbol: "ZARDKK",
      name: "South African Rand / Danish Krone",
    },
    {
      symbol: "ZARHKD",
      name: "South African Rand / Hong Kong Dollar",
    },
    {
      symbol: "ZARMXN",
      name: "South African Rand / Mexican Peso",
    },
    {
      symbol: "ZARNOK",
      name: "South African Rand / Norwegian Krone",
    },
    {
      symbol: "ZARNZD",
      name: "South African Rand / New Zealand Dollar",
    },
    {
      symbol: "ZARSEK",
      name: "South African Rand / Swedish Krona",
    },
    {
      symbol: "ZARSGD",
      name: "South African Rand / Singapore Dollar",
    },
    {
      symbol: "ZARGBP",
      name: "South African Rand / Pound Sterling",
    },
    {
      symbol: "ZARCNY",
      name: "South African Rand / Chinese Yuan",
    },
    {
      symbol: "ZARCHF",
      name: "South African Rand / Swiss Franc",
    },
    {
      symbol: "ZARCAD",
      name: "South African Rand / Canadian Dollar",
    },
    {
      symbol: "ZARKRW",
      name: "South African Rand / South Korean Won",
    },
    {
      symbol: "ZARBRL",
      name: "South African Rand / Brazilian Real",
    },
    {
      symbol: "ZARRUB",
      name: "South African Rand / Russian Ruble",
    },
    {
      symbol: "ZARTRY",
      name: "South African Rand / Turkish Lira",
    },
    {
      symbol: "ZARINR",
      name: "South African Rand / Indian Rupee",
    },
    {
      symbol: "ZARPLN",
      name: "South African Rand / Polish Zloty",
    },
    {
      symbol: "ZARTHB",
      name: "South African Rand / Thai Baht",
    },
    {
      symbol: "BRLUSD",
      name: "Brazilian Real / U.S. Dollar",
    },
    {
      symbol: "BRLEUR",
      name: "Brazilian Real / Euro",
    },
    {
      symbol: "BRLJPY",
      name: "Brazilian Real / Japanese Yen",
    },
    {
      symbol: "BRLAUD",
      name: "Brazilian Real / Australian Dollar",
    },
    {
      symbol: "BRLCZK",
      name: "Brazilian Real / Czech Koruna",
    },
    {
      symbol: "BRLDKK",
      name: "Brazilian Real / Danish Krone",
    },
    {
      symbol: "BRLHKD",
      name: "Brazilian Real / Hong Kong Dollar",
    },
    {
      symbol: "BRLMXN",
      name: "Brazilian Real / Mexican Peso",
    },
    {
      symbol: "BRLNOK",
      name: "Brazilian Real / Norwegian Krone",
    },
    {
      symbol: "BRLNZD",
      name: "Brazilian Real / New Zealand Dollar",
    },
    {
      symbol: "BRLSEK",
      name: "Brazilian Real / Swedish Krona",
    },
    {
      symbol: "BRLSGD",
      name: "Brazilian Real / Singapore Dollar",
    },
    {
      symbol: "BRLGBP",
      name: "Brazilian Real / Pound Sterling",
    },
    {
      symbol: "BRLCNY",
      name: "Brazilian Real / Chinese Yuan",
    },
    {
      symbol: "BRLCHF",
      name: "Brazilian Real / Swiss Franc",
    },
    {
      symbol: "BRLCAD",
      name: "Brazilian Real / Canadian Dollar",
    },
    {
      symbol: "BRLKRW",
      name: "Brazilian Real / South Korean Won",
    },
    {
      symbol: "BRLZAR",
      name: "Brazilian Real / South African Rand",
    },
    {
      symbol: "BRLRUB",
      name: "Brazilian Real / Russian Ruble",
    },
    {
      symbol: "BRLTRY",
      name: "Brazilian Real / Turkish Lira",
    },
    {
      symbol: "BRLINR",
      name: "Brazilian Real / Indian Rupee",
    },
    {
      symbol: "BRLPLN",
      name: "Brazilian Real / Polish Zloty",
    },
    {
      symbol: "RUBUSD",
      name: "Russian Ruble / U.S. Dollar",
    },
    {
      symbol: "RUBEUR",
      name: "Russian Ruble / Euro",
    },
    {
      symbol: "RUBJPY",
      name: "Russian Ruble / Japanese Yen",
    },
    {
      symbol: "RUBAUD",
      name: "Russian Ruble / Australian Dollar",
    },
    {
      symbol: "RUBCZK",
      name: "Russian Ruble / Czech Koruna",
    },
    {
      symbol: "RUBDKK",
      name: "Russian Ruble / Danish Krone",
    },
    {
      symbol: "RUBHKD",
      name: "Russian Ruble / Hong Kong Dollar",
    },
    {
      symbol: "RUBMXN",
      name: "Russian Ruble / Mexican Peso",
    },
    {
      symbol: "RUBNOK",
      name: "Russian Ruble / Norwegian Krone",
    },
    {
      symbol: "RUBNZD",
      name: "Russian Ruble / New Zealand Dollar",
    },
    {
      symbol: "RUBSEK",
      name: "Russian Ruble / Swedish Krona",
    },
    {
      symbol: "RUBSGD",
      name: "Russian Ruble / Singapore Dollar",
    },
    {
      symbol: "RUBGBP",
      name: "Russian Ruble / Pound Sterling",
    },
    {
      symbol: "RUBCNY",
      name: "Russian Ruble / Chinese Yuan",
    },
    {
      symbol: "RUBCHF",
      name: "Russian Ruble / Swiss Franc",
    },
    {
      symbol: "RUBCAD",
      name: "Russian Ruble / Canadian Dollar",
    },
    {
      symbol: "RUBKRW",
      name: "Russian Ruble / South Korean Won",
    },
    {
      symbol: "RUBZAR",
      name: "Russian Ruble / South African Rand",
    },
    {
      symbol: "RUBBRL",
      name: "Russian Ruble / Brazilian Real",
    },
    {
      symbol: "RUBTRY",
      name: "Russian Ruble / Turkish Lira",
    },
    {
      symbol: "RUBINR",
      name: "Russian Ruble / Indian Rupee",
    },
    {
      symbol: "RUBPLN",
      name: "Russian Ruble / Polish Zloty",
    },
    {
      symbol: "TRYUSD",
      name: "Turkish Lira / U.S. Dollar",
    },
    {
      symbol: "TRYEUR",
      name: "Turkish Lira / Euro",
    },
    {
      symbol: "TRYJPY",
      name: "Turkish Lira / Japanese Yen",
    },
    {
      symbol: "TRYAUD",
      name: "Turkish Lira / Australian Dollar",
    },
    {
      symbol: "TRYCZK",
      name: "Turkish Lira / Czech Koruna",
    },
    {
      symbol: "TRYDKK",
      name: "Turkish Lira / Danish Krone",
    },
    {
      symbol: "TRYHKD",
      name: "Turkish Lira / Hong Kong Dollar",
    },
    {
      symbol: "TRYMXN",
      name: "Turkish Lira / Mexican Peso",
    },
    {
      symbol: "TRYNOK",
      name: "Turkish Lira / Norwegian Krone",
    },
    {
      symbol: "TRYNZD",
      name: "Turkish Lira / New Zealand Dollar",
    },
    {
      symbol: "TRYSEK",
      name: "Turkish Lira / Swedish Krona",
    },
    {
      symbol: "TRYSGD",
      name: "Turkish Lira / Singapore Dollar",
    },
    {
      symbol: "TRYGBP",
      name: "Turkish Lira / Pound Sterling",
    },
    {
      symbol: "TRYCNY",
      name: "Turkish Lira / Chinese Yuan",
    },
    {
      symbol: "TRYCHF",
      name: "Turkish Lira / Swiss Franc",
    },
    {
      symbol: "TRYCAD",
      name: "Turkish Lira / Canadian Dollar",
    },
    {
      symbol: "TRYZAR",
      name: "Turkish Lira / South African Rand",
    },
    {
      symbol: "TRYBRL",
      name: "Turkish Lira / Brazilian Real",
    },
    {
      symbol: "TRYRUB",
      name: "Turkish Lira / Russian Ruble",
    },
    {
      symbol: "TRYINR",
      name: "Turkish Lira / Indian Rupee",
    },
    {
      symbol: "TRYPLN",
      name: "Turkish Lira / Polish Zloty",
    },
    {
      symbol: "INRUSD",
      name: "Indian Rupee / U.S. Dollar",
    },
    {
      symbol: "INREUR",
      name: "Indian Rupee / Euro",
    },
    {
      symbol: "INRJPY",
      name: "Indian Rupee / Japanese Yen",
    },
    {
      symbol: "INRAUD",
      name: "Indian Rupee / Australian Dollar",
    },
    {
      symbol: "INRCZK",
      name: "Indian Rupee / Czech Koruna",
    },
    {
      symbol: "INRDKK",
      name: "Indian Rupee / Danish Krone",
    },
    {
      symbol: "INRHKD",
      name: "Indian Rupee / Hong Kong Dollar",
    },
    {
      symbol: "INRMXN",
      name: "Indian Rupee / Mexican Peso",
    },
    {
      symbol: "INRNOK",
      name: "Indian Rupee / Norwegian Krone",
    },
    {
      symbol: "INRNZD",
      name: "Indian Rupee / New Zealand Dollar",
    },
    {
      symbol: "INRSEK",
      name: "Indian Rupee / Swedish Krona",
    },
    {
      symbol: "INRSGD",
      name: "Indian Rupee / Singapore Dollar",
    },
    {
      symbol: "INRGBP",
      name: "Indian Rupee / Pound Sterling",
    },
    {
      symbol: "INRCNY",
      name: "Indian Rupee / Chinese Yuan",
    },
    {
      symbol: "INRCHF",
      name: "Indian Rupee / Swiss Franc",
    },
    {
      symbol: "INRCAD",
      name: "Indian Rupee / Canadian Dollar",
    },
    {
      symbol: "INRKRW",
      name: "Indian Rupee / South Korean Won",
    },
    {
      symbol: "INRZAR",
      name: "Indian Rupee / South African Rand",
    },
    {
      symbol: "INRBRL",
      name: "Indian Rupee / Brazilian Real",
    },
    {
      symbol: "INRRUB",
      name: "Indian Rupee / Russian Ruble",
    },
    {
      symbol: "INRTRY",
      name: "Indian Rupee / Turkish Lira",
    },
    {
      symbol: "INRPLN",
      name: "Indian Rupee / Polish Zloty",
    },
    {
      symbol: "INRTHB",
      name: "Indian Rupee / Thai Baht",
    },
    {
      symbol: "PLNUSD",
      name: "Polish Zloty / U.S. Dollar",
    },
    {
      symbol: "PLNEUR",
      name: "Polish Zloty / Euro",
    },
    {
      symbol: "PLNJPY",
      name: "Polish Zloty / Japanese Yen",
    },
    {
      symbol: "PLNAUD",
      name: "Polish Zloty / Australian Dollar",
    },
    {
      symbol: "PLNCZK",
      name: "Polish Zloty / Czech Koruna",
    },
    {
      symbol: "PLNDKK",
      name: "Polish Zloty / Danish Krone",
    },
    {
      symbol: "PLNHKD",
      name: "Polish Zloty / Hong Kong Dollar",
    },
    {
      symbol: "PLNMXN",
      name: "Polish Zloty / Mexican Peso",
    },
    {
      symbol: "PLNNOK",
      name: "Polish Zloty / Norwegian Krone",
    },
    {
      symbol: "PLNNZD",
      name: "Polish Zloty / New Zealand Dollar",
    },
    {
      symbol: "PLNSEK",
      name: "Polish Zloty / Swedish Krona",
    },
    {
      symbol: "PLNSGD",
      name: "Polish Zloty / Singapore Dollar",
    },
    {
      symbol: "PLNGBP",
      name: "Polish Zloty / Pound Sterling",
    },
    {
      symbol: "PLNCNY",
      name: "Polish Zloty / Chinese Yuan",
    },
    {
      symbol: "PLNCHF",
      name: "Polish Zloty / Swiss Franc",
    },
    {
      symbol: "PLNCAD",
      name: "Polish Zloty / Canadian Dollar",
    },
    {
      symbol: "PLNZAR",
      name: "Polish Zloty / South African Rand",
    },
    {
      symbol: "PLNBRL",
      name: "Polish Zloty / Brazilian Real",
    },
    {
      symbol: "PLNRUB",
      name: "Polish Zloty / Russian Ruble",
    },
    {
      symbol: "PLNTRY",
      name: "Polish Zloty / Turkish Lira",
    },
    {
      symbol: "PLNINR",
      name: "Polish Zloty / Indian Rupee",
    },
    {
      symbol: "PLNTHB",
      name: "Polish Zloty / Thai Baht",
    },
    {
      symbol: "THBUSD",
      name: "Thai Baht / U.S. Dollar",
    },
    {
      symbol: "THBEUR",
      name: "Thai Baht / Euro",
    },
    {
      symbol: "THBJPY",
      name: "Thai Baht / Japanese Yen",
    },
    {
      symbol: "THBAUD",
      name: "Thai Baht / Australian Dollar",
    },
    {
      symbol: "THBDKK",
      name: "Thai Baht / Danish Krone",
    },
    {
      symbol: "THBHKD",
      name: "Thai Baht / Hong Kong Dollar",
    },
    {
      symbol: "THBNOK",
      name: "Thai Baht / Norwegian Krone",
    },
    {
      symbol: "THBNZD",
      name: "Thai Baht / New Zealand Dollar",
    },
    {
      symbol: "THBSEK",
      name: "Thai Baht / Swedish Krona",
    },
    {
      symbol: "THBSGD",
      name: "Thai Baht / Singapore Dollar",
    },
    {
      symbol: "THBGBP",
      name: "Thai Baht / Pound Sterling",
    },
    {
      symbol: "THBCNY",
      name: "Thai Baht / Chinese Yuan",
    },
    {
      symbol: "THBCHF",
      name: "Thai Baht / Swiss Franc",
    },
    {
      symbol: "THBCAD",
      name: "Thai Baht / Canadian Dollar",
    },
    {
      symbol: "THBKRW",
      name: "Thai Baht / South Korean Won",
    },
    {
      symbol: "THBZAR",
      name: "Thai Baht / South African Rand",
    },
    {
      symbol: "THBINR",
      name: "Thai Baht / Indian Rupee",
    },
  ];
  return arr;
}

export function marketsList() {
  return [
    {
      name: "Dow Jones Industrial Average",
      symbol: "^DJI",
      appName: "Dow Jones",
      group: 1,
      pos: 1,
    },
    { name: "S&P 500", appName: "S&P 500", symbol: "^GSPC", group: 1, pos: 2 },
    {
      name: "NASDAQ Composite",
      appName: "Nasdaq Composite",
      symbol: "^IXIC",
      group: 1,
      pos: 3,
    },
    {
      name: "NASDAQ 100",
      appName: "Nasdaq 100",
      symbol: "^NDX",
      group: 1,
      pos: 4,
    },
    {
      name: "Nasdaq Futures",
      appName: "Nasdaq Futures",
      symbol: "NQW00",
      group: 2,
      pos: 1,
    },
    {
      name: "Dow Futures",
      appName: "Dow Futures",
      symbol: "YMW00",
      group: 2,
      pos: 1,
    },
    {
      name: "S&P Futures",
      appName: "S&P Futures",
      symbol: "ESW00",
      group: 2,
      pos: 1,
    },

    {
      name: "Russell 2000",
      appName: "Russell 2000",
      symbol: "^RUT",
      group: 3,
      pos: 1,
    },
    {
      name: "NYSE COMPOSITE (DJ)",
      appName: "NYSE Composite",
      symbol: "^NYA",
      group: 3,
      pos: 2,
    },
    {
      name: "CBOE Volatility Index",
      appName: "CBOE Volatility Index",
      symbol: "^VIX",
      group: 3,
      pos: 3,
    },
    {
      name: "Treasury Yield 5 Years",
      appName: "US 5Year",
      symbol: "^FVX",
      group: 3,
      pos: 4,
    },
    {
      name: "Treasury Yield 10 Years",
      appName: "US 10Year",
      symbol: "^TNX",
      group: 3,
      pos: 5,
    },
    {
      name: "Treasury Yield 30 Years",
      appName: "US 30Year",
      symbol: "^TYX",
      group: 3,
      pos: 6,
    },
    {
      name: "US Debt",
      appName: "US Debt",
      symbol: "DEBT",
      group: 3,
      pos: 7,
    },
    {
      name: "Nikkei 225",
      appName: "Nikkei 225",
      symbol: "^N225",
      group: 4,
      pos: 1,
    },
    {
      name: "HANG SENG INDEX",
      appName: "Hang Seng Index",
      symbol: "^HSI",
      group: 4,
      pos: 2,
    },
    {
      name: "NIFTY 50",
      appName: "NIFTY 50",
      symbol: "^NSEI",
      group: 4,
      pos: 3,
    },
    {
      name: "KOSPI Composite Index",
      appName: "Korean Exchange",
      symbol: "^KS11",
      group: 4,
      pos: 4,
    },
    {
      name: "SSE Composite Index",
      appName: "Shanghai Exchange",
      symbol: "000001.SS",
      group: 4,
      pos: 5,
    },
    {
      name: "STI Index",
      appName: "Singapore",
      symbol: "^STI",
      group: 4,
      pos: 6,
    },

    {
      name: "FTSE 100",
      appName: "FTSE 100",
      symbol: "^FTSE",
      group: 5,
      pos: 1,
    },
    {
      name: "DAX PERFORMANCE-INDEX",
      appName: "DAX",
      symbol: "^GDAXI",
      group: 5,
      pos: 2,
    },
    {
      name: "CAC 40",
      appName: "Euronext Paris",
      symbol: "^FCHI",
      group: 5,
      pos: 3,
    },
    {
      name: "EURONEXT 100",
      appName: "Euronext 100",
      symbol: "^N100",
      group: 5,
      pos: 4,
    },
    {
      name: "XCSE:OMX Stockholm 30 Index",
      appName: "Stockholm",
      symbol: "^OMXS30",
      group: 5,
      pos: 5,
    },
    { name: "IBEX 35", appName: "Madrid", symbol: "^IBEX", group: 5, pos: 6 },
    {
      name: "Austrian Traded Index in EUR",
      appName: "Austrian",
      symbol: "^ATX",
      group: 5,
      pos: 7,
    },
    { name: "BEL 20", appName: "Brussels", symbol: "^BFX", group: 5, pos: 8 },
    { name: "SMI PR", appName: "Swiss", symbol: "^SSMI", group: 5, pos: 9 },
    {
      name: "AEX-INDEX",
      appName: "Amsterdam",
      symbol: "^AEX",
      group: 5,
      pos: 10,
    },
    {
      name: "S&P/ASX 200",
      appName: "Australia/ASX 200",
      symbol: "^AXJO",
      group: 6,
      pos: 1,
    },
    {
      name: "S&P/TSX Composite index",
      appName: "Toronto/TSX",
      symbol: "^GSPTSE",
      group: 6,
      pos: 2,
    },
    {
      name: "IPC MEXICO",
      symbol: "^MXX",
      appName: "IPC Mexico",
      group: 6,
      pos: 3,
    },
    {
      name: "MOEX Russia Index",
      appName: "MOEX Russia",
      symbol: "IMOEX.ME",
      group: 6,
      pos: 4,
    },
    { name: "IBOVESPA", appName: "Brazil", symbol: "^BVSP", group: 5, pos: 5 },
  ];
}

export function commList() {
  return [
    { name: "Gold", symbol: "GCUSD", section: 1 },
    { name: "Silver", symbol: "SIUSD", section: 1 },
    { name: "Copper", symbol: "HGUSD", section: 1 },
    { name: "Platinum", symbol: "PLUSD", section: 1 },
    { name: "Palladium", symbol: "PAUSD", section: 1 },
    //{ name: "Lumber", symbol: "LBSUSD", section: 2 },
    { name: "Cocoa", symbol: "CCUSD", section: 2 },
    { name: "Coffee", symbol: "KCUSX", section: 2 },
    { name: "Corn", symbol: "ZCUSX", section: 2 },
    { name: "Cotton", symbol: "CTUSX", section: 2 },
    { name: "Milk", symbol: "DCUSD", section: 2 },
    { name: "Wheat", symbol: "KEUSX", section: 2 },
    { name: "Oat", symbol: "ZOUSX", section: 2 },
    //{ name: "Rice", symbol: "RRUSD", section: 2 },
    { name: "Orange Juice", symbol: "OJUSX", section: 2 },
    { name: "Soybean", symbol: "ZSUSX", section: 2 },
    { name: "Sugar", symbol: "SBUSX", section: 2 },
    { name: "Lean Hogs", symbol: "HEUSX", section: 3 },
    { name: "Feeder Cattle", symbol: "GFUSX", section: 3 },
    { name: "Live Cattle", symbol: "LEUSX", section: 3 },
    { name: "Crude Oil", symbol: "CLUSD", section: 4 },
    { name: "Gasoline", symbol: "RBUSD", section: 4 },
    { name: "Brent", symbol: "BZUSD", section: 4 },
    { name: "Natural Gas", symbol: "NGUSD", section: 4 },
    { name: "Heating Oil", symbol: "HOUSD", section: 4 },
  ];
}

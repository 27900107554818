import React from "react";
import rssWhite from "../../imgs/rssWhite3.png";
import rssWhite4 from "../../imgs/rssWhite4.png";

import rssBlack from "../../imgs/rssBlack3.png";
import rssOffWhite from "../../imgs/rssOffWhite.png";

function RssButton({
  onClick,
  activeRow,
  darkmode,
  inactive,
  marginLeft = 2,
  marginRight = 2,
  offOpacity = 0.2,
}) {
  // Calculate background color based on props
  const backgroundColor = inactive
    ? activeRow
      ? darkmode
        ? "#2d2d2d"
        : "#ccc"
      : darkmode
      ? "#272727"
      : "#ddd"
    : "#5e7cce";

  // Calculate border style
  const border =
    !darkmode && !inactive && activeRow ? "0.5px solid #ccc" : "0px solid #aaa";

  // Calculate image opacity
  const imageOpacity = inactive
    ? darkmode
      ? offOpacity
      : 0.6
    : darkmode
    ? 0.36
    : 0.92;

  //non darkmode
  let imgPic = rssWhite4;
  if (darkmode) {
    imgPic = rssBlack;
    if (inactive) {
      imgPic = rssWhite;
    }
  }

  return (
    <div
      onClick={onClick}
      style={{
        height: 36,
        width: activeRow ? 36 : 20,
        borderRadius: 2,
        backgroundColor,
        marginRight,
        marginLeft,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border,
      }}
    >
      {activeRow && (
        <img
          src={imgPic}
          style={{
            height: 22,
            width: 22,
            opacity: imageOpacity,
          }}
        />
      )}
    </div>
  );
}

export default RssButton;
